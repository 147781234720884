export default {
  number_format(number, decimals, decPoint, thousandsSep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousandsSep === "undefined" ? "," : thousandsSep,
      dec = typeof decPoint === "undefined" ? "." : decPoint;

    function toFixedFix(n, prec) {
      let k = Math.pow(10, prec);
      return "" + (Math.round(n * k) / k).toFixed(prec);
    }

    let s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  },

  formatAmount(number) {
    number = (number + "").replace(",", ".");
    number = number.replace(/[^0-9+\-Ee.]/g, "");

    let n = !isFinite(+number) ? 0 : +number,
      prec = 2,
      dec = ",";

    function toFixedFix(n, prec) {
      let k = Math.pow(10, prec);
      return "" + (Math.round(n * k) / k).toFixed(prec);
    }

    let s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }

    return s.join(dec);
  },

  scrollTo(target, pos) {
    document
      .getElementById(target)
      .scrollIntoView({ behavior: "smooth", block: pos, inline: pos });
  },
  fillEmailIframe(target, content) {
    let iframe = document.querySelectorAll(`#${target}`)[0];
    let doc = null;

    if (iframe.contentDocument) {
      doc = iframe.contentDocument;
    } else if (iframe.contentWindow) {
      doc = iframe.contentWindow.document;
    }

    doc.open();
    doc.writeln(content);

    [...doc.getElementsByTagName("a")].forEach((a) => (a.target = "_blank"));

    doc.close();
  },
  validateMail(mail) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  },
  deepClone(src) {
    return JSON.parse(JSON.stringify(src));
  },

  validURL(link) {
    let pattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return !!pattern.test(link);
  },

  superUserFilter(val, users) {
    let filteredItems = [],
      filteredValue = val.toLowerCase();

    return filteredItems.concat(
      users.filter((item) => {
        let username = item.username.toLowerCase().includes(filteredValue),
          firstName = item.first_name.toLowerCase().includes(filteredValue),
          lastName = item.last_name.toLowerCase().includes(filteredValue);

        return username || firstName || lastName;
      }),
    );
  },

  confirmPassword(password, repassword) {
    if (password === repassword) {
      return true;
    }
    return false;
  },

  updateObjects(arr, key, value) {
    return arr.map((item) => {
      item[key] = value;
      return item;
    });
  },

  uppercaseArr(arr) {
    let count = arr.length;
    let newArr = [];
    let i;
    for (i = 0; i < count; i++) {
      newArr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return newArr;
  },
  getRandomRgb(opacity) {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = (num >> 8) & 255;
    var b = num & 255;
    return `rgb(${r}, ${g}, ${b}, ${opacity})`;
  },
	anyValueFilled(obj) {
		if (!obj) {
			return false
		}
		for (let key in obj) {
			if (obj.hasOwnProperty(key) && obj[key] !== '') {
				return true;
			}
		}
		return false;
	}
};

