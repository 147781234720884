<template>
    <div class="sidebar" v-if="showSidebar">
        <ul class="nav flex-lg-column" v-if="isResellerUser">
            <li class="nav-item" v-verify-scope-view-parent="userView_parent_orders">
                <router-link
                    class="nav-link side"
                    :class="path === '' ? 'active' : ''"
                    to="/reseller/dashboard"
                >
                    <div>
                        <img src="/img/icons/dashboard.svg">
                        {{ activeLanguage.dashboard.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item">
                 <router-link
                    class="nav-link side"
                    :class="path === 'invoices' ? 'active' : ''"
                    to="/reseller/invoices"
                 >
                     <div class="text-center">
                        <img src="/img/icons/invoices.svg">
                        Invoices
                     </div>
                 </router-link>
            </li>
            <li class="nav-item">
                 <router-link
                    class="nav-link side"
                    :class="path === 'newshop' ? 'active' : ''"
                    to="/newshop"
                 >
                     <div class="text-center">
                        <img src="/img/icons/plus.svg" height="29">
                        {{ activeLanguage.reseller.newshop }}
                     </div>
                 </router-link>
            </li>
        </ul>
        <ul class="nav flex-lg-column" v-else>
            <li class="nav-item" v-verify-scope-view-parent="userView_parent_orders">
                <router-link
                    class="nav-link side"
                    :class="path === '' ? 'active' : ''"
                    to="/"
                >
                    <div>
                        <img src="/img/icons/dashboard.svg">
                        {{ activeLanguage.dashboard.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link
                    class="nav-link side"
                    :class="['order', 'orders'].includes(path) ? 'active' : ''"
                    to="/orders"
                >
                    <div>
                        <img src="/img/icons/orders.svg">
                        {{ activeLanguage.orders.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item" v-verify-scope-view-parent="userView_parent_orders" v-if="hide_invoices === false">
                <template v-if="manageFinancePermission">
                    <a
                            class="nav-link side"
                            :class="path === 'invoices' ? 'active' : ''"
                    >
                        <div>
                            <img src="/img/icons/invoices.svg">
                            {{ activeLanguage.invoices.title }}
                        </div>
                    </a>
                    <div class="collabsible">
                        <div><router-link to="/invoices">{{ activeLanguage.invoices.title }}</router-link></div>
                        <div><router-link to="/invoices/generate">{{ activeLanguage.invoices.generator }}</router-link></div>
                    </div>
                </template>
                <template v-else>
                    <router-link
                            to="/invoices"
                            class="nav-link side"
                            :class="path === 'invoices' ? 'active' : ''"
                    >
                        <div>
                            <img src="/img/icons/invoices.svg">
                            {{ activeLanguage.invoices.title }}
                        </div>
                    </router-link>
                </template>
            </li>
          <li
              class="nav-item"
              v-if="(!!managePermission && managePermission.show_cib_statuses)"
          >
            <router-link
                class="nav-link side"
                :class="path === 'collection' ? 'active' : ''"
                to="/collection"
            >
              <div>
                <img src="/img/icons/collection.svg">
                {{ activeLanguage.collection.title }}
              </div>
            </router-link>
          </li>
            <li
                class="nav-item"
                v-if="(!!managePermission && managePermission.can_use_pending_cib)"
                v-verify-scope-view-parent="userView_parent_orders"
            >
                <router-link
                    class="nav-link side"
                    :class="path === 'cib' ? 'active' : ''"
                    to="/cib"
                >
                    <div>
                        <img src="/img/icons/cib.svg">
                        {{ activeLanguage.cib.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item" v-verify-scope-view-parent="userView_parent_orders">
                <router-link
                    class="nav-link side"
                    :class="path === 'statistics' ? 'active' : ''"
                    :to="!!manageOrdersPermission ? '/employee/statistics' : '/statistics'"
                >
                    <div>
                        <img src="/img/icons/statistics.svg">
                        {{ activeLanguage.statistics.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item" v-if="allowTrx">
                    <router-link
                        class="nav-link side"
                        :class="path === 'trxproduct' || path === 'trxmodule' ? 'active' : ''"
                        :to="!!allowTrx ? '/trxmodule' : '/trxmodule'"
                    >
                        <div class="text-center">
                            <img src="/img/icons/trx.svg">
                            TRX
                        </div>
                    </router-link>
            </li> 
            <li class="nav-item">
                <router-link
                    to="/dispute/employee/group/show"
                    class="nav-link side"
                    :class="path === 'dispute' ? 'active' : ''"
                >
                    <div>
                        <img src="/img/icons/disputev2.svg">
                        {{ activeLanguage.customerService.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item" v-if="manageOrdersPermission">
                    <a
                        class="nav-link side"
                        :class="path === 'blacklist' ? 'active' : ''"
                    >
                        <div>
                            <img src="/img/icons/toolbox.svg">
                            Tools
                        </div>
                    </a>
                    <div class="collabsible">
                        <div><router-link to="/blacklist">Blacklist</router-link></div>
                        <div><router-link to="/check-logs">Check logs</router-link></div>
                        <div><router-link to="/nps">NPS</router-link></div>
                        <div v-if="hasIdinScope"><router-link to="/idin">{{ activeLanguage.tools.validated_users }}</router-link></div>
                    </div>
            </li> 
            <li class="nav-item" v-if="!!access_client_services">
                <router-link
                        class="nav-link side apiIcon"
                        :class="path === 'webshop' ? 'active' : ''"
                        to="/webshop"
                >
                    <div class="text-center">
                        <img src="/img/icons/shop.svg">
                        {{ activeLanguage.shoppingstreet.title }}
                    </div>
                </router-link>
            </li>
            <li class="nav-item" v-verify-scope-view-parent="userView_parent_orders">
                <router-link
                        class="nav-link side apiIcon"
                        :class="path === 'api' ? 'active' : ''"
                        to="/api"
                >
                    <div class="text-center">
                        <img src="/img/icons/api.svg">
                        {{ activeLanguage.api.title }}
                    </div>
                </router-link>
            </li>
            <!-- <li class="nav-item">
                 <router-link
                    class="nav-link side"
                    :class="path === 'settings' ? 'active' : ''"
                    to="/settings"
                 >
                     <div class="text-center">
                        <img src="/img/icons/settings.svg">
                        {{ activeLanguage.settings.title }}
                     </div>
                 </router-link>
                    <div class="collabsible">
                        <div><router-link to="/settings">{{ activeLanguage.settings.accountSettings }}</router-link></div>
                        <div v-if="!manageOrdersPermission" v-verify-scope-view-parent="userView_parent_orders"><router-link to="/webshop">{{ activeLanguage.settings.shop }}</router-link></div>
                    </div>
             </li>  -->
        </ul>
    </div>
</template>

<script>
	import { mapGetters } from  "vuex";

    export default {
        name: 'sidebar',
        props:{
            activeLanguage: {
                type: Object,
                required: true
            },
	        disputeExists: {
		        type: Boolean,
		        required: true
            }
        },
        created(){
            this.showSidebar = localStorage.getItem('Btoken') ? true : false
        },
        mounted() {
            this.checkPath()
            this.hideInvoices()
        },
        data () {
            return {
                collapse: {},
                path: '',
                hide_invoices: false,
                showSidebar: false
            }
        },
	    computed: {
		    ...mapGetters({
                manageOrdersPermission: 'userManage_orders',
                manageFinancePermission: 'userManage_finance',
                managePermission: 'getBillinkUserPermissions',
                userView_parent_orders: 'userView_parent_orders',
                accessClientApiServices: 'access_client_api_services',
                manageBlacklist: 'userManageBlacklist',
                isResellerUser: 'isResellerUser',
                allowTrx: 'getTrxPermissions',
                hasIdinScope: 'hasIdinScope',
                access_client_services: 'access_client_services'
		    }),
	    },
        watch: {
            '$route': 'checkPath'
        },
        methods: {
            checkPath() {
            	let path = this.$route.path.slice(1, this.$route.path.length);
                if (path.search('/') > -1) { path = path.slice(0, path.search('/')) }
                this.path = path
            },
            hideInvoices() {
              if (this.managePermission) {
                let token = JSON.parse(localStorage.getItem('Btoken'))
                this.hide_invoices = this.managePermission.hide_invoices
                token.hide_invoices = this.managePermission.hide_invoices
                localStorage.setItem('Btoken', JSON.stringify(token))
              }
            }
        }
    }
</script>

<style scoped lang="scss">

    .collabsible {
        display: none;
    }

    .nav-item:hover {
        .collabsible{
            display: block;
            transition: 0.5 ease-out;
        }
    }
    .nav-link.side{
        cursor: pointer;
        &+div a{
            padding: 4px;
            color: #032438;
            display: block;
            padding-left: 20px;
            font-size: 14px;
            background-color: #39a7a5;
            cursor: pointer;
            &:hover {
                background: #009a97;
            }
        }
        &.apiIcon img{
            width: 45px;
            height: 45px;
        }
    }
    @media (max-width:991px) {
        .sidebar {
            display: none;
        }
    }
</style>
