<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <h1>
                    {{ activeLanguage.title }}
                    <!--<a data-toggle="collapse" href="#help-panel"><i class="fa fa-question-circle question" aria-hidden="true"></i></a>-->
                </h1>
            </div>
            <div class="row">
                <div class="form-group col-md-4 mb-3" v-if="manageOrdersPermission">
                    <label for="input6" class="col-form-label" style="padding-top: 0;">{{ activeLanguage.client }}</label>
                    <v-autocomplete
                            :min-len="2"
                            v-model="client"
                            :items="clients"
                            :get-label="getLabel"
                            :component-item='itemTemplate'
                            @update-items="updateClientList"
                            @item-selected="clientSelected"
                    >
                    </v-autocomplete>
                </div>
            </div>
            <p class="row"><strong class="col-12">{{ activeLanguage.import }}</strong></p>
            <div class="row">
                <div class="col-md-6">
                    <label
                        @dragover="(event) => event.preventDefault()"
                        @dragenter="(event) => event.preventDefault()"
                        @drop="drop"
                        for="csvUploadInput"
                        style="width: 100%;cursor: pointer;"
                    >
                        <input type="file" id="csvUploadInput" style="display: none;" @change="uploadCsv($event, 'normal')">
                        <div class="importer">
                            <div class="importer-inner">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                <p>
                                    {{ activeLanguage.uploadTxt }}<br />
                                    <small>max 2mb</small>
                                </p>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-md-3">
                    <p>
                        <strong>{{ activeLanguage.tip }}</strong><br />
                        {{ activeLanguage.tipTxt }}
                    </p>
                    <p>
                        <a href="" @click.prevent="downloadExample">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                            {{ activeLanguage.download }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
	import ClientItemTemplate from '../components/common/reusable/ClientItemTemplate.vue'

	export default {
		name: "credit-import",
        data() {
			return {
				clients: [],
				client: null,
				client_id: null,
				itemTemplate: ClientItemTemplate,
            }
        },
        methods: {
			drop(e) {
				e.stopPropagation()
                e.preventDefault()
				this.uploadCsv(e, 'drop')
            },
			uploadCsv(e, type) {
				let file = type === 'drop' ? e.dataTransfer.files[0] : e.target.files[0]

				if (!file) { return false }

                if ('csv' !== file.name.split('.').pop().toLowerCase()) {
	                return this.resetInput('CSV only')
                }

                if (file.size > 2000000) {
                    return this.resetInput('File size shouldn\'t be more than 2 MB')
                }

                let formData = new FormData()
                formData.append("import", file)

                if (!!this.client_id) {
	                formData.append("client", this.client_id)
                }

                billinkAxios
                    .post(`app/import-credit`, formData)
                    .then(({ data }) => {
	                    EventBus.$emit('showAlertModal', this.activeLanguage.successUpload)
                    })
                    .catch(error => {
                        console.error(error)
                        EventBus.$emit('showAlertModal', error.response.data.message || 'Something went wrong')
                    })
                    .then(() => this.resetInput())

            },
	        downloadExample() {
	        	billinkAxios
                    .get('app/import-credit/example', { responseType: 'blob' })
                    .then((res) => {
	                    let link = document.createElement('a')

	                    link.href = window.URL.createObjectURL(res.data)
	                    link.setAttribute('download', 'Example.csv');
	                    document.body.appendChild(link);
	                    link.click();
	                    link.remove();
                    })
                    .catch(err => {
	                    console.error(err)
	                    EventBus.$emit('showAlertModal', 'Something went wrong')
                    })
            },
            resetInput(mes) {
				document.getElementById('csvUploadInput').value = null

                if (!!mes) {
	                EventBus.$emit('showAlertModal', mes)
                }
            },
	        updateClientList(val) {
		        if (this.isSuperUser) {
			        this.$store.dispatch('startLoader')
			        this.clients = Helper.superUserFilter(val, this.computedConnectedUsers)
			        this.$store.dispatch('endLoader')
		        } else {
			        billinkAxios
				        .post('app/clients/list', { username: val })
				        .then(({data}) => this.clients = data.clients)
				        .catch(err => console.error(err))
		        }
	        },
	        clientSelected(val) {
		        this.client = val
		        this.client_id = val.id
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getImportCreditLanguage',
				manageOrdersPermission: 'userManage_orders',
			})
		},
	}
</script>

<style scoped lang="scss">

</style>