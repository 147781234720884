const state = {
    filters: {}
}

const mutations = {
    'SET_ORDERS_FILTERS': (state, filters) => {
        state.filters = filters
    }
}

const actions = {
    setOrdersFilters: ({commit}, filters) => {
        commit('SET_ORDERS_FILTERS', filters)
    },
    destroyOrdersFilters: ({commit}) => {
        commit('SET_ORDERS_FILTERS', {})
    }
}

const getters = {
    getOrdersFilters: state => state.filters
}

export default { state, mutations, actions, getters }