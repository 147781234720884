const state = {
	token: {},
	codeMessage: '',
	codeErrorMessage: ''
}

const mutations = {
	'SET_BILLINK_TOKEN': (state, token) => {
		state.token = token
	},
	'SET_CODE_MESSAGE': (state, message) => {
		state.codeMessage = message
	},
	'SET_CODE_ERROR_MESSAGE': (state, errorMessage) => {
		state.codeErrorMessage = errorMessage
	}
}

const actions = {
	billinkLogIn: ({commit, dispatch}, {username, password, code}) => {
		return new Promise((resolve, reject) => {
			let headers = {'Content-Type': 'multipart/form-data'},
				data = new FormData()

			data.append('username', username);
			data.append('password', password);
			data.append('code', process.env.VUE_APP_BILLINK_AUTH_CODE);
			data.append('grant_type', 'authorization_code');
			data.append('two_factor_auth', true);
			if (code) data.append('verification_code', code);

			billinkAxios
				.post('app/authorize-app', data, { headers })
				.then(({ data }) => {
					if (data.message) {
						commit('SET_CODE_MESSAGE', data.message)
					} else {
						commit('SET_CODE_MESSAGE', {})
					}

					let logData = data

					if (logData.details && !!logData.details.is_super && !!data.details.connected_users) {
						logData.details.computedConnectedUsers = Object
							.keys(logData.details.connected_users)
							.map((item) => {
								return logData.details.connected_users[item]
							})
					}

					dispatch('setBillinkToken', data)
					resolve()
				})
				.catch(err => {
					if (err.response.data.trace) commit('SET_CODE_ERROR_MESSAGE', err.response.data.error)
					
					dispatch('destroyBillinkToken')
					reject(err)
				})
		})
	},
	setBillinkToken: ({commit}, token) => {
		return new Promise((resolve, reject) => {
			token.refresh_token = token.refresh_token || state.token.refresh_token
			localStorage.setItem('Btoken', JSON.stringify(token))
			commit('SET_BILLINK_TOKEN', token)
			resolve(token)
		})
	},
	destroyBillinkToken({commit}) {
		localStorage.removeItem('Btoken')
		commit('SET_BILLINK_TOKEN', {});
	}
}

const getters = {
	getBillinkToken: state => state.token,
	getBillinkUserDetails: state => state.token.details,
	getBillinkUserPermissions: state => state.token.permissions,
	isSuperUser: state => state.token.details && !!state.token.details.is_super,
	getComputedConnectedUsers: state => state.token.details && state.token.details.computedConnectedUsers,
	isAdmin: state => state.token.scope && state.token.scope.includes('admin'),
	userManage_orders: state => state.token.scope && state.token.scope.includes('manage_orders'),
	userManage_clients: state => state.token.scope && state.token.scope.includes('manage_clients'),
	userManage_finance: state => state.token.scope && state.token.scope.includes('manage_finance'),
	userView_parent_orders: state => state.token.scope && state.token.scope.includes('view_parent_orders'),
	access_client_api_services: state => state.token.scope && state.token.scope.includes('access_client_api_services'),
	access_client_services: state => state.token.scope && state.token.scope.includes('access_client_services'),
	userManageBlacklist: state => state.token.scope && state.token.scope.includes('manage_black_list'),
	isClient: state => state.token.scope && state.token.scope.includes('manage_own_orders'),
	isResellerUser: state => state.token.scope && state.token.scope.includes('access_reseller_statistics'),
	getCodeMessage: state => state.codeMessage,
	getCodeErrorMessage: state => state.codeErrorMessage,
	getTrxPermissions: state => state.token.permissions.trx_enabled,
	hasIdinScope: state => state.token.scope && state.token.scope.includes('access_id_validation_statistics')
}


export default { state, mutations, actions, getters }
