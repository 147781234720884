<template>
    <b-modal
        v-model="showModal"
        hide-header
        hide-footer
    >
        <div class="row">
            <div class="col-12" style="font-weight: 600;font-size: 18px;text-align: center;">
                {{ message }}
            </div>
        </div>
    </b-modal>
</template>

<script>
	export default {
		name: "AlertModal",
        data() {
			return {
				showModal: false,
                message: ''
            }
        },
        mounted() {
	        EventBus.$on(
	        	'showAlertModal',
                (message) => {
                    this.showModal = true
                    this.message = message
                }
            )
        },
        methods: {
			close() {
				this.showModal = false
				this.message = ''
            }
        }
	}
</script>

<style scoped>

</style>