const InvoiceReseller = () => import('../../views/invoicesReseller/Invoices.vue')
const Settings = () => import('../../views/SettingsReseller.vue'),
      DashboardReseller = () => import('../../views/DashboardReseller.vue')

export default [
    {
        path: '/reseller/settings',
        name: 'reseller.settings',
        component: Settings
    },
    {
		path: '/reseller/invoices',
		name: 'InvoiceReseller',
		component: InvoiceReseller
    }
]