import checks from '../checks'

const Clients = () => import('../../views/clients/Clients'),
	CreateClient = () => import('../../views/clients/CreateEditClient'),
	CreateWebshop = () => import('../../views/clients/CreateEditWebshop')

export default [
	{
		path: '/clients',
		name: 'clients',
		component: Clients
	},
	{
		path: '/clients/create/client',
		name: 'create.client',
		component: CreateClient
	},
	{
		path: '/clients/edit/client/:id',
		name: 'edit.client',
		component: CreateClient
	},
	{
		path: '/clients/create/webshop',
		name: 'create.webshop',
		component: CreateWebshop
	},
	{
		path: '/clients/edit/webshop/:id',
		name: 'edit.webshop',
		component: CreateWebshop
	}
]