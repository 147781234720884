const state = {
	userInfo: {}
}


const mutations = {
	'SET_USER': (state, info) => {
		state.userInfo = info
	}
}

const actions = {
	disputeLogIn: (({commit, dispatch}, billinkData) => {
		return new Promise((resolve, reject) => {
			let headers = {
				'Content-Type': 'application/json',
				'Authorization': 'smthWentWrong'
			}, logData = {
				username: billinkData.username,
				email: billinkData.email || billinkData.username.toLowerCase() + '@billink.nl',
				id: billinkData.id,
				role: billinkData.role ? 'ROLE_EMPLOYEE' : 'ROLE_CLIENT',
				grant_type: 'password',
				client_id: process.env.VUE_APP_DISPUTE_CLIENT_ID,
				client_secret: process.env.VUE_APP_DISPUTE_CLIENT_SECRET,
				billinkToken: billinkData.billinkToken
			}

			disputeAxios
				.post('/api/user/exists', { billinkId: logData.id || 1 }, { headers })
				.then(({ data }) => {
					if (data.code === 2002) {
						resolve()
					} else {
						disputeAxios
							.post('/api/user/create', logData, { headers })
							.then(({ data }) => resolve())
							.catch(err => {
								reject(err)
								console.error(err)
							})
					}
				})
				.catch(err => {
					reject(err)
					console.error(err)
				})
		})
	}),
	setUser({commit}) {
		disputeAxios.get('/api/user/info')
			.then(({data}) => commit('SET_USER', data))
			.catch(err => console.error(err))
	}
}

const getters = {
	getUserInfo: state => state.userInfo
}

export default { state, mutations, actions, getters }
