import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Autocomplete from 'v-autocomplete'
import vueHljs from "vue-hljs"
import VeeValidate from 'vee-validate'
import Loading from 'vue-loading-overlay'
import VueProgressBar from 'vue-progressbar'
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment'
import vClickOutside from 'v-click-outside'
import VuePhoneNumberInput from 'vue-phone-number-input';

import App from './App.vue'
import router from './routes/index'
import store from './store/index'
import filters from './filters/index';
import directives from './directives/index'
import Helpers from './helpers/index'

import '../node_modules/font-awesome/css/font-awesome.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import 'v-autocomplete/dist/v-autocomplete.css'
import "../node_modules/vue-hljs/dist/vue-hljs.min.css"
import 'vue-loading-overlay/dist/vue-loading.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import './scss/main.scss'

VeeValidate.Validator.extend(
	'phone', {
		getMessage: field => 'The ' + field + ' field is not valid.',
		validate: value => {
			const re = new RegExp(/^\+?\(?\d{1,3}\)?\d{8,15}$/g)
			return re.test(value)
	}
});
VeeValidate.Validator.extend(
	'dateOfBirth', {
		getMessage: field => 'The ' + field + ' field must be over 16 years.',
		validate: value => moment().subtract(16, 'years') >= moment(value)
	}
);

Vue.use(BootstrapVue)
Vue.use(VuePhoneNumberInput);
Vue.use(Autocomplete)
Vue.use(vueHljs)
Vue.use(vClickOutside)
Vue.use(filters)
Vue.use(directives)
Vue.use(Loading)
Vue.use(VeeValidate, {
	events: '',
	strict: false
})
Vue.use(VueProgressBar, {
	color: '#f48d21',
	failedColor: 'red',
	thickness: '3px',
	position: 'absolute',
	autoFinish: false
})
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
	}
})

window.billinkAxios = Helpers.billinkAxios
window.newbillinkAxios = Helpers.newbillinkAxios
window.statisticsAxios = Helpers.statisticsAxios
window.disputeAxios = Helpers.disputeAxios
window.checkAxios = Helpers.checkAxios
window.standartAxios = Helpers.standartAxios
window.shoppingAxios = Helpers.shoppingAxios
window.mfaAxios = Helpers.mfaAxios
window.orderAxios = Helpers.orderAxios
window.trackerAxios = Helpers.trackerAxios
window.Helper = Helpers.HelperScripts
window.EventBus = new Vue()


let Btoken = JSON.parse(localStorage.getItem('Btoken'))
if (Btoken && Btoken.access_token) { store.dispatch('setBillinkToken', Btoken) }
store.dispatch('changeLanguage', localStorage.getItem('localization') || 'dutch')

Vue.config.productionTip = false

window.DD_RUM && window.DD_RUM.init({
	clientToken: process.env.VUE_APP_DATADOG_TOKEN,
	applicationId: process.env.VUE_APP_DATADOG_ID,
});

export default new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
