const ApiDocumentation = () => import('../../views/api/ApiDocumentation.vue'),
	ApiLogs = () => import('../../views/api/ApiLogsList.vue')

export default [
	{
		path: '/api',
		name: 'api',
		component: ApiDocumentation
	},
	{
		path: '/api/logs',
		name: 'api.logs',
		component: ApiLogs
	}
]