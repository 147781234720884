<template>
    <div style="position: relative;">
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script>
	export default {
		name: "ProgressBar"
	}
</script>

<style scoped>

</style>