<template>
    <b-modal v-model="show"
             size="lg"
             :hide-footer="true"
             header-class="card-header pause">
             <div class="row">
                 <div class="col-md-12 d-flex login_modal">
                    <p class="errText">{{errorMessage ? errorMessage : 'De combinatie van het e-mailadres en het wachtwoord is niet bij ons bekend'}}</p>
                 </div>
                 <div class="col-md-12 d-flex" style="justify-content: flex-end;">
                     <button class="btn btn-primary" style="border: none;" @click="close">Probeer het opnieuw</button>
                 </div>
             </div>
    </b-modal>
</template>

<script>
export default {
    props: ['errorMessage'],
    data () {
        return {
            show: false
        }
    },
    methods: {
        open () { this.show = true },
        close () { this.show = false }
    }
}
</script>

<style lang="scss">
    .login_modal {
        justify-content: center;
        margin-bottom: 20px;
        p {
          font-size: 16px;
        }
    }
    .errText {
        width: 100%;
        text-align: center;
        font-size: 16px;
    }
</style>