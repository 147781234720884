import checks from '../checks'
const Orders = () => import('../../views/orders/Orders.vue'),
	OrderNew = () => import('../../views/orders/OrderNew.vue'),
	OrderDetails = () => import('../../views/orders/OrderDetails.vue')

export default [
	{
		path: '/orders',
		name: 'orders',
		component: Orders
	},
	{
		path: '/orders/new',
		name: 'orders.new',
		component: OrderNew,
		beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
	},
	{
		path: '/order/:id',
		name: 'orders.item',
		meta: {
			samePageRedirect: false
		},
		component: OrderDetails
	},
]