import store from "../index";

const state = {
	locale: {
		"activeLanguage": "",
		"respErrorMsg": "",
		"header": {
			"selectLanguage": {},
			"headerDropdown": {},
			"headerNotification": {}
		},
		"sidebar": {
			"dashboard": {},
			"orders": {},
			"invoices": {},
			"clients": {},
			"cib": {},
			"statistics": {},
			"help": {},
			"api": {},
			"customerService": {},
			"collection": {},
			"trx": {},
			"settings" : {},
			"tools": {},
			"shoppingstreet": {}
		},
		"helpSidebar": {
			"faqArr": [],
		},
		"login": {},
		"dashboard": {
			"lastOrdersTable": {},
			"totalRavenuTable" : {
				"months": {
					"monthArr": [],
				}
			},
			"billinkInvoicesTable": {
				"title": "",
				"billinkClient": "",
				"date": "",
				"amount": "",
				"selectOptions": [],
				"viewAllInvoicesBtn": ""
			}
		},
		"orders": {
			"filterTable": {},
			"pagTxt": {},
			"ordersTable": {}
		},
		"orderDetails": {
			"pauseModal": {},
			"fraudModal": {},
			"clientDetails": {},
			"orderInfo": {},
			"activityHistory": {},
			"orderInfoTable": {},
			"employeeClientData": {},
			"employeeOrderData": {},
			"paymentTable": {},
			"creditTable": {},
			"disputeListTable": {},
			"addPaymentBlock": {},
			"addCreditBlock": {
				"productCreditTable": {}
			}
		},
		"orderNew": {

		},
		"invoices": {
			"invoiceFilter": {},
			"invoiceTable": {},
		},
		"invoiceGeneration": {},
		"statistics": {
			"months": {
				"monthArr": []
			}
		},
		"apiDocumentation": {
			"apiLogs": {
				"apiLogsFilter": {},
				"apiLogsTable": {}
			},
			"text": '',
			"check": {
				"deskTable": [],
				"resCodes": [],
			},
			"order": {
				"deskTable": [],
				"deskInvTable": [],
				"resCodes": []
			},
			"status": {
				"deskTable": [],
				"deskInvTable": [],
				"resDesc": [],
				"payDesk": [],
				"resCodes": [],
			},
			"startWorkflow": {
				"deskTable": [],
				"deskInvTable": [],
				"resCodes": [],
			},
			"credit": {
				"deskTable": [],
				"deskInvTable": [],
				"resCodes": [],
			},
			"payment": {
				"deskTable": [],
				"deskInvTable": [],
				"resCodes": [],
			},
			"onHold": {
				"deskTable": [],
				"resCodes": []
			},
			"file": {
				"deskTable": [],
				"resCodes": []
			},
			"message": {
				"deskTable": [],
				"resCodes": []
			},
			"topics": {
				"titleList": {
					"intro": ''
				},
				"options": {},
				"titles": {},
				"resTxt": {}
			},
			"help": {
				"helpdesk": {
					"tel": {},
					"email": {}
				},
				"hours": {}
			},
		},
		"disputeDetails": {
			"disputeDetailsTable": {
				"resolved": {}
			},
			"history": {
				"mesModal": {}
			},
		},
		"cib": {},
		"settings": {
			"passTable": {},
			"otherTable": {},
			"mfaTable": {},
			"logoTable": {},
			"help": {
				"helpdesk": {
					"tel": {},
					"email": {}
				},
				"hours": {}
			}
		},
		"pageTitle": {},
		"disputeGroups": {
			"groupTable": {},
			"categories" : {}
		},
		"disputeCreate": {
			"categories":[],
			"confirm": {}
		},
		"disputeShow": {
			"infoTable": {},
			"disputeTable": {}
		},
		"clients": {},
		"importCredit": {},
		"shoppingMall": {
			"fields_label" : {},
			"hints": {}
		},
		"helpDesk": {
			"helpdesk": {
				"tel": {},
				"email": {}
			},
			"hours": {}
		},
		"errorPage": {},
		"reseller": {
			"newshop": {}
		},
		"nps": {
			"filterTable": {},
			"pagTxt": {},
			"npsTable": {}
		},
		"statisticsEmployee": {},
		"collection": {},
		"automationRules": {},
		"trx": {},
		"idin": {}
	}
}


const mutations = {
	'CHANGE_LANGUAGE': (state, data) => {
		state.locale = data;
	},
}

const actions = {
	changeLanguage: async ({commit}, lang) => {
		let data = await import(`../../locale/${lang}.json`)

		localStorage.setItem('localization', lang)
		commit('CHANGE_LANGUAGE', data)
	},
	
}

const getters = {
	getActiveLanguageName: state => state.locale.activeLanguage,
	getHeaderLanguage: state => state.locale.header,
	getSidebarLanguage: state => state.locale.sidebar,
	getHelpSidebarLanguage: state => state.locale.helpSidebar,
	getLoginLanguage: state => state.locale.login,
	getDashboardLanguage: state => state.locale.dashboard,
	getOrdersLanguage: state => state.locale.orders,
	getOrderDetailsLanguage: state => state.locale.orderDetails,
	getOrderNewLanguage: state => state.locale.orderNew,
	getInvoicesLanguage: state => state.locale.invoices,
	getInvoiceGenerationLanguage: state => state.locale.invoiceGeneration,
	getStatisticsLanguage: state => state.locale.statistics,
	getApiDocLanguage: state => state.locale.apiDocumentation,
	getSettingsLanguage: state => state.locale.settings,
	getDisputeDetailsLanguage: state => state.locale.disputeDetails,
	getDisputeShowLanguage: state => state.locale.disputeShow,
	getDisputeCreateLanguage: state => state.locale.disputeCreate,
	getDisputeGroupsLanguage: state => state.locale.disputeGroups,
	getPageTitleLanguage: state => state.locale.pageTitle,
	getClientsLanguage: state => state.locale.clients,
	getCIBLanguage: state => state.locale.cib,
	getImportCreditLanguage: state => state.locale.importCredit,
	getShoppingMallLanguage: state => state.locale.shoppingMall,
	getHelpDeskLanguage: state => state.locale.helpDesk,
	getErrorPage: state => state.locale.errorPage,
	getResellerLanguage: state => state.locale.reseller,
	getNPSLanguage: state => state.locale.nps,
	getStatisticsEmployeeLanguage: state => state.locale.statisticsEmployee,
	getCollectionLanguage: state => state.locale.collection,
	getAutomationRulesLanguage: state => state.locale.automationRules,
	getTrxLanguage: state => state.locale.trx,
	getIdinLanguage: state => state.locale.idin,
}



export default {state, mutations, actions, getters}
