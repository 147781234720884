const state = {
    showHelpModal: false
}


const mutations = {
    'TOGGLE_HELP_MODAL': (state) => {
        state.showHelpModal = !state.showHelpModal;
    },
	'CLOSE_HELP_MODAL': (state) => {
		state.showHelpModal = false;
	}
}

const actions = {
	toggleHelpModal: ({commit}) => {
		commit('TOGGLE_HELP_MODAL');
	},
	closeHelpModal: ({commit}) => {
		commit('CLOSE_HELP_MODAL');
	}
}

const getters = {
	showHelpModal: state => state.showHelpModal
}

export default {state, mutations, actions, getters}
