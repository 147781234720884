import store from "../index";

const state = {
	tasks: 0,
	disputeVisibility: false
}

const mutations = {
	'UPDATE_TASKS': (state, tasks) => {
		state.tasks = tasks
	},
	'UPDATE_DISPUTE': (state, bool) => {
		state.disputeVisibility = bool
	},

}

const actions = {
	updateTasks: ({commit}) => {
		disputeAxios
			.get('/api/employee-dispute/tasks')
			.then(({data}) => commit('UPDATE_TASKS', data.tasks))
			.catch(err => {
				if (err.response.status === 400) {
					router.push({ path: '/login' })
					store.dispatch('destroyBillinkToken')
				}
				console.error(err)
			})
	},
	disputeVisibility: ({commit}) => {
		disputeAxios
			.get('/api/employee-dispute/groups')
			.then(({ data }) => commit('UPDATE_DISPUTE', !!data.length || store.getters.userManage_orders))
			.catch(err => {
				if (err.response.status === 400) {
					router.push({ path: '/login' })
					store.dispatch('destroyBillinkToken')
				}
				console.error(err)
			})
	},
}

const getters = {
	getTasksAmount: state => state.tasks,
	getDisputeVisibility: state => state.disputeVisibility,
}

export default {state, mutations, actions, getters}
