import Vue from 'vue'
import Router from 'vue-router'

import checks from './checks'

import Login from '../views/Login.vue'
import ChangePassword from '../views/ChangePassword.vue'
import CreditImport from '../views/CreditImport.vue'
import Shoppingmall from '../views/Shoppingmall.vue'
import ErrorPage from '../views/ErrorPage'
import newShop from '../views/newShop/newShop.vue'

import orderRoutes from './modules/orders'
import invoiceRoutes from './modules/invoices'
import disputeRoutes from './modules/disputes'
import clientRoutes from './modules/clients'
import apiRoutes from './modules/apiDoc'
import ToolsRoutes from './modules/tools'
import resellerRouters from './modules/resellers'

const Dashboard = () => import('../views/Dashboard.vue'),
	Statistics = () => import('../views/Statistics.vue'),
	UserOverride = () => import('../views/UserOverride.vue'),
	Cib = () => import('../views/cib/Cib.vue'),
	Collection = () => import('../views/collection/Collection.vue'),
	DashboardReseller = () => import('../views/DashboardReseller.vue'),
	StatisticsEmployee = () => import('../views/StatisticsEmployee.vue'),
	TrxModule = () => import('../views/trx/TrxModule.vue'),
	TrxNewProduct = () => import('../views/trx/TrxNewProduct.vue'),
	TrxProductList = () => import('../views/trx/TrxProductList.vue'),
	TrxTransactionList = () => import('../views/trx/TrxTransactionList.vue'),
	TrxCreateTransaction = () => import('../views/trx/TrxCreateTransaction.vue'),
	iDin = () => import('../views/idin/Idin.vue'),
	Account = () => import('../views/settings/Account.vue')

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'login',
			component: Login
		},
		{
			path: '/',
			name: 'dashboard',
			component: Dashboard,
			beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
		},
		{
			path: '/reseller/dashboard',
			name: 'reseller.dashboard',
			component: DashboardReseller,
			beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
		},
		{
			path: '/credit/import',
			name: 'credit.import',
			component: CreditImport,
			beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
		},
		{
			path: '/statistics',
			name: 'statistics',
			component: Statistics,
			beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
		},
		{
			path: '/employee/statistics',
			name: 'StatisticsEmployee',
			component: StatisticsEmployee,
			beforeEnter: (to, from, next) => checks.manageViewParentOrders(to, from, next)
		},
		{
			path: '/user/override',
			name: 'userOverride',
			component: UserOverride,
			beforeEnter: (to, from, next) => checks.manageClientsCheck(to, from, next)
		},
		{
			path: '/cib',
			name: 'CIB',
			component: Cib,
			beforeEnter: (to, from, next) => checks.manageCibCheck(to, from, next)
		},
		{
			path: '/collection',
			name: 'Collection',
			component: Collection,
			beforeEnter: (to, from, next) => checks.manageCollectionCheck(to, from, next)
		},
		{
			path: '/user/change-password/',
			query: { key: 'private' },
			name: 'changeUserPassword',
			component: ChangePassword
		},
		{
			path: '/webshop',
			name: 'webshop',
			component: Shoppingmall
		},
		...orderRoutes,
		...invoiceRoutes,
		...disputeRoutes,
		...clientRoutes,
		...apiRoutes,
		...ToolsRoutes,
		...resellerRouters,
		{
			path: '*',
			redirect: '/error/404'
		},
		{
			path: '/error/:status',
			name: 'error.page',
			component: ErrorPage
		},
		{
			path: '/newshop',
			name: 'shop.new',
			component: newShop
		},
		{
			path: '/trxmodule',
			name: 'trxModule',
			component: TrxModule,
			beforeEnter: (to, from, next) => checks.manageTrxCheck(to, from, next)
		},
		{
			path: '/trxnewproduct',
			name: 'trxNewProduct',
			component: TrxNewProduct,
			beforeEnter: (to, from, next) => checks.manageTrxCheck(to, from, next)
		},
		{
			path: '/trxproductlist',
			name: 'trxProductList',
			component: TrxProductList,
			beforeEnter: (to, from, next) => checks.manageTrxCheck(to, from, next)
		},
		{
			path: '/trxtransactionlist',
			name: 'trxTransactionList',
			component: TrxTransactionList,
			beforeEnter: (to, from, next) => checks.manageTrxCheck(to, from, next)
		},
		{
			path: '/trxcreatetransaction',
			name: 'trxCreateTransaction',
			component: TrxCreateTransaction,
			beforeEnter: (to, from, next) => checks.manageTrxCheck(to, from, next)
		},,
		{
			path: '/idin',
			name: 'idin',
			component: iDin,
			beforeEnter: (to, from, next) => checks.manageIdinCheck(to, from, next)
		},
		{
			path: '/settings',
			name: 'settings',
			component: Account
		}
	],
	scrollBehavior(to, from, savedPosition) {
		return {x: 0, y: 0};
	}
})

router.beforeEach((to, from, next) => {
	if (!localStorage.getItem('Btoken') &&
		(!['changeUserPassword', 'login'].includes(to.name))) {
		next(false)
		router.push({ path: '/login',  query: { redirect: to.fullPath } })
		location.reload(true)
	} else {
		billinkAxios
                    .get('app/maintenance/info')
                    .then(({ data }) => {})
                    .catch(err => {
                        console.error(err)
						store.dispatch('endLoader');

                        if (err.response.status === 401) {
                            router.push({ path: '/login',  query: { redirect: to.fullPath } })
							location.reload(true)
                        }
                    })
		next()
	}
})

export default router