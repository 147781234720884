<template>
    <div class="main">
        <b-modal v-model="showModal"
             @ok="close"
             @cancel="close"
             size="lg"
             header-class="card-header pause"
             class="pause-txt">
        <div class="row d-flex">
            <h6>Offerte is succesvol verstuurd naar de merchant.</h6>
        </div>
        </b-modal>
        <div class="container">
            <div class="title">
                <h1>{{ activeLanguage.newshop.title }}</h1>
            </div>
            <p>
                {{ activeLanguage.newshop.title_desc }}
                <br/>
                {{ activeLanguage.newshop.title_desc2 }}
                <br/>
                {{ languagePutUserId }}
            </p>
            <FormShop @onsubmit="onSubmit" ref="formShop" :language="activeLanguage.newshop.form"/>
        </div>
    </div>
</template>
<script>
import {mapGetters} from  'vuex'
import FormShop from '../../components/newShop/FormShop.vue'
export default {
    data() {
        return {
            form_data: {},
            text_success: false,
            showModal: false    
       }
    },
    mounted() {
    },
    methods: {
        onSubmit(data) {
            this.addCompany(data)
        },
        addCompany(obj) {
            this.form_data = obj
            let data = {
                name: obj.company_name,
                email: obj.email_admin,
                telephone: obj.tel_admin,
                country: 'NL',
                zipcode: obj.postal_code,
                city: obj.city,
                street: obj.street,
                number:  obj.house_number,
                website: obj.url_webshop,
                vat_code: obj.vat,
                local_business_number: obj.coc_number,
                custom_field_61628: obj.bic,
                custom_field_94893: obj.trade_name,
                custom_field_59250: obj.iban,
                custom_field_339717: this.getUser.id,
                custom_field_62735: obj.platform,
                custom_field_61637: obj.legally_valid,
                custom_field_62053: obj.url_webshop,
                custom_field_59252: obj.bankaccount,
                description: `Contactpersoon ${obj.contact_person} ${obj.last_name} <br>Telefoonnummer voor debiteuren: ${obj.tel_customer} <br>Emailadres voor debiteuren: ${obj.email_customer}`
            }
            billinkAxios.post('/app/reseller/add-company', data)
                    .then(({ data }) => {
                        if (data.message === 'Impossible to continue operation. Please create first TL access token.') {
                            EventBus.$emit('showAlertModal', data.message)
                        } else {
                            this.addDeal(data.message, obj.garant)
                        }
                        
                    })
                    .catch(err => {
                        console.error(err)
                        if (err.response.data.errors && err.response.data.errors[0]) {
                            EventBus.$emit('showAlertModal', err.response.data.errors[0].title)
                        } else if (err.response.data.message) {
                            EventBus.$emit('showAlertModal', err.response.data.message)
                        }

                    })
        },
        addDeal(id, garant) {
            let data = {
                contact_or_company: 'company',
                contact_or_company_id: id.id,
                title: 'via partnerportaal',
                // source: 'app.billink.nl',
                description: 'Dit is een geautomatiseerde offerte.',
                // price_1: '0.00',
                amount_1: '0.00',
                // vat_1: '0',
                // product_1: 'Offerte',
                description_1: 'Offerte',
                // subtitle_1: 'Offerte',
                custom_field_254282: '3%',
                custom_field_254284: '3%',
                custom_field_254302: '3%',
                custom_field_266354: 'N.V.T.',
                custom_field_266352: 'N.V.T.',
                custom_field_256066: '€150',
                custom_field_254150: '€1.35',
                custom_field_254267: '€1.35',
                custom_field_254268: '€1.35',
                custom_field_266353: 'N.V.T.',
                custom_field_256068: '597050',
                custom_field_256070: 'N.V.T.',
                custom_field_65532: 'N.V.T.',
                custom_field_65533: 'N.V.T.',
                custom_field_65534: '2.5%',
                custom_field_65535: '2.5%',
                custom_field_65526: '€10.000',
                custom_field_65525: '€750',
                custom_field_65527: '€0.75',
                custom_field_65528: '€1.25',
                custom_field_65529: 'N.V.T.',
                custom_field_65531: 'N.V.T.',
                custom_field_65523: 'N.V.T.',
                custom_field_94896: 'N.V.T.',
                custom_field_65530: '0.49',
                custom_field_252261: garant,
                custom_field_252257: 'N.V.T.'
            }

            billinkAxios.post('/app/reseller/add-deal', data)
                    .then(( dealData ) => {
                        billinkAxios.post('/app/reseller/tax-rates/list')
                            .then(( list ) => {
                                let rateId = list.data.message[0].id
                                let quotData = {
                                    deal_id: dealData.data.message.id,
                                    title: data.title,
                                    quantity: data.amount_1,
                                    description: data.description_1,
                                    amount: data.amount_1,
                                    tax_rate_id: rateId
                                }
                                billinkAxios.post('/app/reseller/add-quotation', quotData)
                                    .then(( quotation ) => {
                                        this.sendSaleToClient(quotation.data.message.id)
                                    })
                                    .catch(err => {
                                        console.error(err)
                                    })
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    })
                    .catch(err => {
                        console.error(err)
                    })
        },
        sendSaleToClient(id) {
            let data = {
                quotation_id: id,
                email: this.form_data.email_admin,
                subject: 'Geautomatiseerde Offerte',
                content: `Beste ${this.form_data.contact_person}  Jullie partner ${this.getUser.username} heeft via ons portaal jullie webshop aangemeld. Graag ontvangen wij een ondertekende offerte om hiervoor een account aan te kunnen maken.#LINK Met vriendelijke groet, Team Billink.`,
                language: this.getActiveLanguageName === 'english' ? 'en' : 'nl'
            }
            billinkAxios
					.post('/app/reseller/send-sale-to-client', data)
					.then(({data}) => {
                        if (data.status === 'success') {
                               this.showModal = true
                               this.$refs.formShop.clear()
                            }
                    })
					.catch(err => {
                        console.error(err)
                        if (err.response.data.errors && err.response.data.errors[0]) {
                            EventBus.$emit('showAlertModal', err.response.data.errors[0].title)
                        } else if (err.response.data.message) {
                            EventBus.$emit('showAlertModal', err.response.data.message)
                        }

                    })
        },
        close () {
            this.showModal = false
        },
        open () {
            this.showModal = true
        },
    },
    computed: {
		...mapGetters({
            getUser: 'getBillinkUserDetails',
            activeLanguage: 'getResellerLanguage',
            getActiveLanguageName: 'getActiveLanguageName'
		}),
        languagePutUserId() {
            let regExp = /\[[RESELLERID\]]*\]/
            return this.activeLanguage.newshop.title_desc3.replace(regExp, this.getUser.id)
        }
	},
    components: {
        FormShop
    }
}
</script>
<style lang="scss" scoped>
    .padd-0 {
        padding: 0;
    }
    .d-flex {
        display: flex;
        justify-content: center;
    }
</style>