<template>
  <div id="app">
      <template v-if="logwr">
          <HeaderTop
              :activeLanguage="activeHeaderLanguage"
              :tasks="disputeTasks"
              :disputeExists="disputeVisibility"
          />
          <Sidebar
              :activeLanguage="activeSidebarLanguage"
              :disputeExists="disputeVisibility"
          />
          <HelpSidebar :activeLanguage="activeHelpSidebarLanguage"/>
      </template>
      <router-view/>
      <AlertModal />
  </div>
</template>

<script>
// @ is an alias to /src
import HelpSidebar from '@/components/common/HelpSidebar.vue'
import HeaderTop from '@/components/common/HeaderTop.vue'
import Sidebar from '@/components/common/Sidebar.vue'
import AlertModal from './components/common/reusable/AlertModal'
import store from './store/index'
import router from './routes/index'

import {mapGetters} from  "vuex";

export default {
	name: 'Main',
    data () {
		return {
			logwr: false
        }
    },
    components: {
        HeaderTop,
        HelpSidebar,
        Sidebar,
	    AlertModal
    },
    mounted() {
	    this.checkLoginPage()
      if (!!this.btoken.access_token) this.getDisputeInfo()

      this.checkTokenInterval = setInterval(() => {
        this.checkTokenExpiry();
      }, 300000);

      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.checkTokenInterval);
      });
    },
	computed: {
		...mapGetters({
			activeHeaderLanguage: 'getHeaderLanguage',
			activeSidebarLanguage: 'getSidebarLanguage',
			activeHelpSidebarLanguage: 'getHelpSidebarLanguage',
			activeTitleLanguage: 'getPageTitleLanguage',
            btoken: 'getBillinkToken',
            disputeTasks: 'getTasksAmount',
            disputeVisibility: 'getDisputeVisibility'
		})
	},
	watch:{
		$route (to, from) {
			this.setPageTitle()
			this.checkLoginPage()
		},
		activeTitleLanguage (to, from) { this.setPageTitle() },
		btoken (to, from) { if (!!to.access_token && window.location.pathname !== '/login') this.getDisputeInfo()}
	},
	methods: {
    isTokenExpired() {
      const token = store.getters.getBillinkToken;
      if (!token || !token.expires_at) {
        return true;
      }
      const currentTime = Math.floor(Date.now() / 1000);
      return currentTime >= token.expires_at;
    },
    checkTokenExpiry() {
      if (this.isTokenExpired()) {
        store.dispatch('destroyBillinkToken');
        router.push({ path: '/login' });
      }
    },
		getDisputeInfo() {
			setTimeout(() => {
				this.$store.dispatch('updateTasks')
				this.$store.dispatch('disputeVisibility')
            }, 1000)
		},
		checkLoginPage() {
			this.logwr = !['changeUserPassword', 'login'].includes(this.$route.name)
        },
		setPageTitle() {
            switch (this.$route.name) {
                case 'login':
                    document.title = this.activeTitleLanguage.login || 'Billink'
                    break;
                case 'dashboard':
                    document.title = this.activeTitleLanguage.dashboard || 'Billink'
                    break;
                case 'orders':
                    document.title = this.activeTitleLanguage.orders || 'Billink'
                    break;
                case 'invoices':
                    document.title = this.activeTitleLanguage.invoices || 'Billink'
                    break;
                case 'invoices.generate':
                    document.title = this.activeTitleLanguage.invoiceGeneration || 'Billink'
                    break;
                case 'settings':
                    document.title = this.activeTitleLanguage.settings || 'Billink'
                    break;
                case 'statistics':
                    document.title = this.activeTitleLanguage.statistics || 'Billink'
                    break;
                case 'api':
                    document.title = this.activeTitleLanguage.api || 'Billink'
                    break;
	            case 'api.logs':
		            document.title = this.activeTitleLanguage.apiLogs || 'Billink'
		            break;
                case 'orders.item': break;
                default:
                    document.title = 'Billink'
                    break;
				}
			// merge it later !
			let path = this.$route.path.slice(1, this.$route.path.length);
			if (path.search('/') > -1) { path = path.slice(0, path.search('/')) }

			switch (path) {
				case 'dispute':
					document.title = this.activeTitleLanguage.dispute || 'Billink'
					break;
			}
        }
    }
}
</script>

<style lang="scss">
    body {
        position: relative;
    }
    .loading-overlay.is-full-page {
        z-index: 1051 !important;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0,0,0,.05);
    }
    .page-item .page-link {
        &:focus, &:active {
            box-shadow:none !important;
        }
    }
    .pagination {
        .page-item {
          .page-link {
            border: 1px solid #032438 !important;
          }
            &:hover .page-link {
                background-color: #eee !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.active:hover .page-link {
                background-color: #f48d21 !important;
                border-color: #f48d21 !important;
                color: #ffffff !important;
                box-shadow: none !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.active .page-link {
                color: #ffffff !important;
                background-color: #f48d21 !important;
                border-color: #f48d21 !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.disabled .page-link {
              border: 1px solid #032438 !important;
            }
            &.disabled:hover .page-link {
                background-color: #fff !important;
            }
        }
        &.green .page-item{
            &:hover .page-link {
                background-color: #eee !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.active:hover .page-link {
                background-color: #4db5af !important;
                border-color: #4db5af !important;
                color: #ffffff !important;
                box-shadow: none !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.active .page-link {
                color: #ffffff !important;
                background-color: #4db5af !important;
                border-color: #4db5af !important;
                &:focus, &:active {
                    box-shadow: none !important;
                }
            }
            &.disabled .page-link {
                border: 1px solid #dee2e6 !important;
                //opacity: 0.8;
            }
            &.disabled:hover .page-link {
                background-color: #fff !important;
            }
        }
    }
    .main {
        background-color: #fff;
        min-height: 100vh;
    }
    .card-header {
        background: #032438;
        color: #fff;
        text-transform: uppercase;
        font-size: 0.8795rem;
        font-family: 'Titillium Web', Arial, 'Helvetica Neue', Helvetica, sans-serif;
        border-radius: 0 !important;
    }
    .breadcrumb{
        padding: 8px 0;
        background-color: transparent;
    }
    .small-date {
        display: none;
    }
    .padding.my-4 {
        a, button {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .custom-control-indicator {
        border-radius: .25rem;
        position: absolute;
        top: .25rem;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #ddd;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .newOrder{
        .btn.float-right {
            float: none !important;
            text-align: center;
            display: block;
            margin: auto;
        }
    }
    .modal-header.card-header .close {
        opacity: 0;
        color: #ffffff;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-indicator {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }
    .v-autocomplete-input{
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .v-autocomplete-list {
        max-height: 414px;
        overflow: scroll;
    }
    @media (max-width: 480px) {
        .table-res {
            display: none !important;
        }
        .small-date {
            display: block !important;
            font-size: 9px;
        }
    }
    @media (max-width:991px) {
        body {
            padding-top: 50px;
        }
    }
    @media (max-width: 460px) {
        .new-order-link, .table-bar{
            display: none;
        }
        .filter-bar{
            > div {
                display: flex;
                flex-flow: column-reverse;
                .form-inline.float-right {
                    float: none !important;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    input {
                        width: 80%;
                    }
                    button {
                        width: 20%;
                    }
                }
            }
        }
    }
    @media (max-width: 480px) {
        .vdp-datepicker__calendar-button{
            padding: 8px 4px;
        }
        .vdp-datepicker{
            .form-control {
                padding: .5rem;
            }
        }
    }
    .col-form-label{
        font-size: 13px;
        padding-right: 0px;
        &.addition {
            padding-left: 0;
            text-align: center;
        }
    }
    @media (max-width: 576px) {
        .col-form-label.addition {
            padding-left: 15px;
            text-align: left;
        }
    }
    @media (max-width: 480px) {
        .hidden-xs {
            display: none !important;
        }
    }
</style>
