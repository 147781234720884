import Vue from 'vue'

const state = {
	loader: null
}

const mutations = {
	'START_LOADER': (state) => {
		state.loader = Vue.$loading.show({
			container: null,
			canCancel: true,
		})
	},
	'END_LOADER': (state) => {
		if (!!state.loader) { state.loader.hide() }
	},
}

const actions = {
	startLoader: ({commit, dispatch}) => {
		if (!!state.loader) { dispatch('endLoader') }
		commit('START_LOADER')
	},
	endLoader: ({commit}) => commit('END_LOADER')
}

export default {state, mutations, actions}
