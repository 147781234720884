<template>
    <div class="main">
        <div class="container">
            <div class="mainTitle">
                <h1 style="display: flex;">{{ activeLanguage.title }}</h1>
                <div class="slider" style="margin-top: 8px;" @click="form.active = !form.active" :class="form.active ? 'active' : ''">
                    <div class="point"></div>
                </div>
                <div class="help-tip">
                    <p>{{ activeLanguage.hints.toggle }}</p>
                </div>
            </div>
            <div class="titleDescription">
                <p>
                    {{ activeLanguage.description_1 }}
                </p>
                <p style="margin-left: 1ch;">
                    {{ activeLanguage.description_2  }}
                </p>
            </div>
            <div class="row divider">
                <h2>{{ activeLanguage.brand_identity }}</h2>
            </div>
            <div class="row logos">
                <div class="logoContainer" @dragover.prevent @drop.prevent>
                    <div class="logoTitle">
                        <p>
                            {{ activeLanguage.fields_label.white_logo }}
                        </p>
                        <div class="help-tip">
                            <p>{{ activeLanguage.hints.whiteLogo }}</p>
                        </div>
                    </div>
                    <div class="logoField" @drop="dragFile($event, 'white')">
                        <a
                            @click.prevent="deleteLogo('white_logo_base64')"
                            v-if="!!form.white_logo_base64"
                            href=""
                            class="deleteLogo hidden-xs"
                        >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </a>
                    <input type="file" @change="uploadLogo($event, 'white')" class="customFileInput" />
                        <div class="logo offset-md-4" style="display: contents;" v-if="!!form.white_logo_base64">
                                    <img :src="form.white_logo_base64" class="logoImg" />
                        </div>
                        <div class="logoInner" v-else>
                            <p>500x500px</p>
                            <p>Max file size 2MB</p>
                        </div>
                    </div>
                </div>

                <div class="logoContainer" @dragover.prevent @drop.prevent>
                    <div class="logoTitle">
                        <p>
                            {{ activeLanguage.fields_label.background_image }}
                        </p>
                        <div class="help-tip">
                            <p>{{ activeLanguage.hints.backgroundImage }}</p>
                        </div>
                    </div>
                    <div class="logoField" @drop="dragFile($event, 'background')">
                        <a
                            @click.prevent="deleteLogo('background_image_base64')"
                            v-if="!!form.background_image_base64"
                            href=""
                            class="deleteLogo hidden-xs"
                        >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </a>
                    <input type="file" @change="uploadLogo($event, 'background')" class="customFileInput" />
                        <div class="logo offset-md-4" style="display: contents;" v-if="!!form.background_image_base64">
                                    <img :src="form.background_image_base64" class="logoImg" />
                        </div>
                        <div class="logoInner" v-else>
                            <p>500x500px</p>
                            <p>Max file size 2MB</p>
                        </div>
                    </div>
                </div>

                <div class="logoContainer" @dragover.prevent @drop.prevent>
                    <div class="logoTitle">
                        <p>
                            {{ activeLanguage.fields_label.normal_logo }}
                        </p>
                        <div class="help-tip">
                            <p>{{ activeLanguage.hints.normalLogo }}</p>
                        </div>
                    </div>
                    <div class="logoField" @drop="dragFile($event, 'logo')">
                        <a
                            @click.prevent="deleteLogo('logo_base64')"
                            v-if="!!form.logo_base64"
                            href=""
                            class="deleteLogo hidden-xs"
                        >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </a>
                    <input type="file" @change="uploadLogo($event, 'logo')" class="customFileInput" />
                        <div class="logo offset-md-4" style="display: contents;" v-if="!!form.logo_base64">
                                    <img :src="form.logo_base64" class="logoImg" />
                        </div>
                        <div class="logoInner" v-else>
                            <p>500x500px</p>
                            <p>Max file size 2MB</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row divider">
                <h2>{{ activeLanguage.webshop_information }}</h2>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <form @submit.prevent="onSubmit" class="mainForm">
                        <div class="forms">
                            <div class="form-group height d-flex" style="flex-direction: column;">
                                <div class="space-input-label"><label class="labelField">{{ activeLanguage.fields_label.webshop  }}</label></div>
                                <input type="text" class="form-control d-inline-block" v-model="form.webshop_name" :placeholder="activeLanguage.fields_label.webshop" required />
                            </div>
                            <div class="form-group height d-flex" style="flex-direction: column;">
                                <div class="space-input-label"><label class="labelField">{{ activeLanguage.fields_label.link }}</label></div>
                                <input type="text" class="form-control d-inline-block" v-model="form.link" :placeholder="activeLanguage.fields_label.placeholderLink+':'+' https://www.billink.nl'" required />
                            </div>
                            <div class="form-group block-description height d-flex" style="display: flex; flex-direction: column; margin-bottom: 0px">
                                <div class="space-input-label">
                                    <label class="labelField">{{ activeLanguage.fields_label.description  }}</label>
                                    <div class="help-tip">
                                        <p>{{ activeLanguage.hints.description }}</p>
                                    </div>
                                </div>
                                <textarea class="form-control d-inline-block" v-model="form.description" :placeholder="activeLanguage.fields_label.description" required></textarea>
                            </div>
                        </div>
                        <div class="categories">
                            <div class="form-group height d-flex" style="flex-direction: column; height: 100%;">
                                <div class="space-input-label">
                                    <label class="labelField">{{ activeLanguage.category  }}</label>
                                </div>
                                <div class="d-inline-block form-select catMultiselect" style="height: 100%;">
                                    <select class="custom-select" style="height: 100%;" v-model="form.tags" multiple :select-size="15" required>
                                        <option v-for="item in categories" :value="item.slug" :key="item.id">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
            <div class="row divider">
                <h2>{{ activeLanguage.discounts }}</h2>
            </div>
            <div class="row">
                <div class="col-md-12 saleBlock">
                    <div class="sale">
                    <div class="form-group height d-flex" style="flex-direction: column;">
                        <div class="space-input-label">
                            <label class="labelField">{{ activeLanguage.fields_label.active }}</label>
                            <div class="help-tip">
                                <p>{{ activeLanguage.hints.sale }}</p>
                            </div>
                        </div>
                            <div class="slider" style="margin-left: 0px" @click="changeSaleStatus" :class="sale_active ? 'active' : ''">
                                <div class="point"></div>
                            </div>
                    </div>
                </div>
                <div class="saleAmount">
                    <div class="form-group height">
                        <label for="discount_type" class="labelField">{{ activeLanguage.discount_type }}</label>
                        <div class="input-group">
                            <select name="discount_type" id="discount_type" v-model="form.discount_type" class="form-control" style="height: 35px;" :disabled="!sale_active">
                                <option value="percentage">{{ activeLanguage.percentage}}</option>
                                <option value="fixed">{{ activeLanguage.fixed}}</option>
                                <option value="free_shipping">{{ activeLanguage.free_shipping}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="saleAmount" v-if="form.discount_type === 'percentage'">
                    <div class="form-group height d-flex" style="flex-direction: column;">
                        <div class="space-input-label">
                            <label class="labelField">{{ activeLanguage.fields_label.percentage }}</label>
                        </div>
                        <masked-input
                            type="text"
                            class="form-control d-inline-block"
                            style="width: 65px;"
                            v-model="form.sale_percentage"
                            :mask="numMask"
                            :placeholder="'%'"
                            :disabled="!sale_active"
                        />
                    </div>
                </div>

                <div class="saleAmount" v-if="form.discount_type === 'fixed'">
                    <div class="form-group height d-flex" style="flex-direction: column;">
                        <div class="space-input-label">
                            <label class="labelField">{{ activeLanguage.fixed_label }}</label>
                        </div>
                        <masked-input
                            type="text"
                            class="form-control d-inline-block"
                            style="width: 65px;"
                            v-model="form.sale_percentage"
                            :mask="numMask"
                            :disabled="!sale_active"
                        />
                    </div>
                </div>

                <div class="saleDate">
                    <div class="form-group height d-flex" style="flex-direction: column;">
                        <div class="space-input-label">
                            <label class="labelField">{{ activeLanguage.fields_label.date }}</label>
                            <div class="help-tip">
                                <p>{{ activeLanguage.hints.date }}</p>
                            </div>
                        </div>
                        <div class="form-group" style="display: inline-flex;">
                                    <div class="input-group">
                                        <Datepicker
                                            v-model="form.sale_start_date"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.fields_label.from"
                                            format="dd-MM-yyyy"
                                            :clear-button="true"
                                            :disabled="!sale_active"
                                        />
                                    </div>
                                    <div class="input-group" style="margin-left: 12px;">
                                        <Datepicker
                                            v-model="form.sale_end_date"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.fields_label.to"
                                            format="dd-MM-yyyy"
                                            :clear-button="true"
                                            :disabled="!sale_active"
                                        />
                                    </div>

                                </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group height d-flex" style="flex-direction: column;">
                        <div class="space-input-label">
                            <label class="labelField">{{ activeLanguage.discount_code  }}</label>
                        </div>
                            <input type="text" class="form-control d-inline-block" v-model="form.discount_code" :disabled="!sale_active" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group block-description height d-flex" style="display: flex; flex-direction: column; margin-bottom: 20px">
                                <div class="space-input-label">
                                    <label class="labelField">{{ activeLanguage.discount_description  }}</label>
                                </div>
                                <textarea class="form-control d-inline-block" v-model="form.discount_description" :disabled="!sale_active"></textarea>
                            </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                        <button type="button" @click="onSubmit" class="btn">{{ activeLanguage.fields_label.submit  }}</button>
                        <button type="button" target="_blank" class="btn shop_link" @click="openLink">{{ activeLanguage.fields_label.showInShoppingstreet }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HelpDesk from '../components/common/reusable/HelpDesk.vue'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import MaskedInput from 'vue-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default {
    data() {
        return {
            form: {
                active: true,
                tags: [],
                sale_start_date: null,
                sale_end_date: null,
                sale_percentage: null,
                white_logo_base64: null,
                background_image_base64: null,
                logo_base64: null,
                discount_type: null,
                discount_code: null,
                discount_description: null
            },
            sale_active: true,
            file: '',
            filename: '',
            categories: [],
            numMask: createNumberMask({
					prefix: '',
					allowDecimal: true,
                    allowNegative: false,
                    decimalLimit: 2,
                    thousandsSeparatorSymbol: '.',
                    decimalSymbol: ','
				}),
        }
    },
    mounted() {
        this.getInfo()
        this.filename = this.getFilename(this.file)
    },
    methods: {
        handleEv(e){
            e.preventDefault()

            let formattedTags = this.form.tags
            if (this.form.tags.length < 5 ) {
                e.target.selected = !e.target.selected
            } else if (e.target.selected) {
                e.target.selected = !e.target.selected
            }
            if (e.target.selected) {
                if(this.form.tags.length < 5 ) {
                    if (!formattedTags.includes(e.target.value)) {
                        formattedTags.push(e.target.value)
                    }
                }
                } else {
                    formattedTags = formattedTags.filter(item => item !== e.target.value)
                }
                this.form.tags = formattedTags
            
        },
        changeSaleStatus() {
            this.sale_active = !this.sale_active
        },
        onSubmit() {
            let { id } = this.userDetails
            this.form.link = this.transformToHttp(this.form.link)
            this.form.carousel = this.form.active ? '1' : '0'
            this.form.sale_active = this.sale_active ? '1' : '0'
            this.form.sale_start_date = this.form.sale_start_date ? moment(this.form.sale_start_date).format('YYYYMMDD') : null
            this.form.sale_end_date = this.form.sale_end_date ? moment(this.form.sale_end_date).format('YYYYMMDD') : null
            shoppingAxios.post(`/shop/${id}`, this.form)  
                .then(resp => {
                    let msg = this.language === 'english' ? 'Successfully' : 'Opgeslagen' 
                    EventBus.$emit('showAlertModal', msg) 
                    this.getInfo()
                })
              .catch(err => EventBus.$emit('showAlertModal', 'Something went wrong!'))
        },
        getInfo () {
            let { id } = this.userDetails
            shoppingAxios.get(`/shop/${id}`)
                .then(({ data }) => {
                    this.form = data
                    this.form.link = this.transformToHttp(this.form.link)
                    this.filename = data.logo === '' ? '' : data.logo
                    this.sale_active = data.sale_active
                    if (data.sale_start_date === "Invalid date") {
                        this.form.sale_start_date = null
                    } else if (data.sale_start_date) {
                        let formatStartDate = data.sale_start_date.substring(4, 6) + "/" + data.sale_start_date.substring(6, 8) + "/" + data.sale_start_date.substring(0, 4);
                        this.form.sale_start_date = new Date(formatStartDate)
                    }
                    if (data.sale_end_date === "Invalid date") {
                        this.form.sale_end_date = null
                    } else if (data.sale_end_date) {
                        let formatEndDate = data.sale_end_date.substring(4, 6) + "/" + data.sale_end_date.substring(6, 8) + "/" + data.sale_end_date.substring(0, 4);
                        this.form.sale_end_date = new Date(formatEndDate)
                    }
                })
                .catch(err => console.error(err))

                shoppingAxios.get(`shop/categories`)
                    .then(({ data }) => {
                        this.categories = data
                    })
                    .catch(err => console.error(err))
        },
        uploadLogo(e, type) {
            let file = e.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                switch(true) {
                    case type === 'logo':
                        this.form.logo_base64 = reader.result;
                        break;
                    case type === 'white':
                        this.form.white_logo_base64 = reader.result;
                        break;
                    case type === 'background':
                        this.form.background_image_base64 = reader.result;
                        break;
                }
            }
        },
        getFilename (path) {
            let index = path.indexOf('shops/')
            let filename = path.substr(index, path.length)
                              .replace('shops/', '')
            return filename    
        },
        transformToHttp (link) {
            let wwwReg = /(\w*www\w*.)/
            let httpsReg = /(https):\/\//gi
            if (!wwwReg.test(link)) {
                link = `www.${link}`
                if (!httpsReg.test(link)) {
                    link = `https://${link}`
                }
            } else {
                if (!httpsReg.test(link)) {
                    link = `https://${link}`
                }
            }
            return link
        },
        openLink() {
            window.open(this.form.shop_id_link)
        },
      setSelectedItem(arg) {
        this.form.tags = arg
      },
      dragFile(e, type) {
        let file = e.dataTransfer.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                switch(true) {
                    case type === 'logo':
                        this.form.logo_base64 = reader.result;
                        break;
                    case type === 'white':
                        this.form.white_logo_base64 = reader.result;
                        break;
                    case type === 'background':
                        this.form.background_image_base64 = reader.result;
                        break;
                }
            }
      },
      deleteLogo(type) {
        switch(true) {
            case type === 'logo_base64':
                this.form.logo_base64 = null;
                break;
            case type === 'white_logo_base64':
                this.form.white_logo_base64 = null;
                break;
            case type === 'background_image_base64':
                this.form.background_image_base64 = null;
                break;
            }
      },
    },
    watch: {
        },
    computed: {
        ...mapGetters({
            activeLanguage: 'getShoppingMallLanguage',
            userDetails: 'getBillinkUserDetails',
            language: 'getActiveLanguageName'
        }),
        placeholderFileInput () {
            return this.filename || this.activeLanguage.fields_label.file
        },
        requiredFile () {
            return this.filename === '' ? true : false
        }
    },
    components: {
        HelpDesk,
        Datepicker,
        MaskedInput
    }
}
</script>

<style lang="scss" scoped>
    .deleteLogo {
        color: #f48d21;
        z-index: 20;
        position: absolute;
        padding: 7px;
        margin-left: 240px;
    }

    input[type="text"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
        border: 1px solid #4db5af !important;
        box-shadow: 0 0 0 0.2rem rgba(77, 181, 175, 0.25) !important;
    }

    .actual_range input[type="text"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
            border: 1px solid #4db5af !important;
            box-shadow: 0 0 0 0.2rem rgba(77, 181, 175, 0.25) !important;
        }
    .divider {
        border-bottom: 2px solid grey;
        margin-left: 0px;
        h2 {
            margin-bottom: 0px;
            font-size: 22px;
        }
    }

    .mainTitle {
        display: inline-flex;
        h1 {
            color: #4db5af;
        }
    }
    .titleDescription {
        display: flex;
        font-weight: bold;
    }
    .logos {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
    }
    .logoTitle {
        display: flex;
        justify-content: center;
        p {
            text-align: center;
            font-weight: 800;
            margin-bottom: 0.5rem;
        }
    }
    .logoField {
        width: 268px;
        height: 268px;
        border-radius: 10px;
        background-color: #DBDADD;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .logoInner {
        margin-top: auto;
        margin-bottom: auto;
    }
    .logoImg {
        max-width: 268px;
        max-height: 268px;
        border-radius: 10px;
        margin: auto;
    }
    .active {
         background: #4DB5AF !important;
         content: 'On';
        .point {
            background: white !important;
            transform: translateX(10px);
        }
    }
    .mainForm {
        display: flex;
        flex-direction: row;
        margin-top: 25px;
    }
    .forms {
        width: 50%;
        padding-right: 20px;
    }
    .categories {
        width: 50%;
    }
    .catMultiselect {
        width: 100% !important;
    }
    .customFileInput {
        width: 268px;
        height: 268px;
        position: absolute;
        opacity: 0;
    }
    .saleBlock {
        display: inline-flex;
        margin-top: 25px;
    }
    .sale {
        margin-right: 35px;
    }
    .saleAmount {
        margin-right: 35px;
    }
    .height {
        height: var(--height);
    }
    textarea {
        height: 123px;
    }
    input:required {
        box-shadow: none;
    }

    textarea:required {
        box-shadow: none;
    }

    .required {
        color: red;
    }

    .slider-block {
        display: inline-block;
    }

    .form-select {
        width: 50%;
    }

    .slider {
        width:40px;
        height: 20px;
        background: #DBDADD;
        border-radius: 13px;
        cursor: pointer;
        padding: 0px 7px;
        margin-left: 20px;
        margin-top: 5px;
        .point {
            width: 18px;
            height: 20px;
            background: #989898;
            transition: .2s ease;
            border-radius: 23px;
        }
    }
    .saleSlider {
        margin-left: 0;
        width: 85px;
        height: 30px;
        .point {
            width: 30px;
            height: 30px;
            background: #989898;
            transition: .2s ease;
            border-radius: 23px;
        }
    }
    .d-inline-block { display: inline-block; }
    .space-input-label {
        // width: 190px;
        display: flex;
    }
    .form-select {
        padding: 0 !important;
    }
    .labelField {
        font-weight: 800;
    }
    .form-logo-btn { left: -201px; }
    @media (min-width: 320px) and (max-width: 480px) {  
        .block-help {
            display: none;
        }
        .block-description {
            display: inline-block !important;
        }
        .form-select {
            width: 100%;
        }
    }
    @media (min-width: 481px) and (max-width: 767px) {
        .block-help {
            margin-top: 30px;
            > .row {
                justify-content: center !important;
            }
        }
        .block-description {
            display: inline-block !important;
            width: 100%;
        }
        .form-select {
            width: 100%;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        .block-description {
            display: inline-block !important;
        }
        .space-input-label {
            width: 163px;
            display: inline-block;
        }
    }
    .shop_link {
        // color: gray;
        margin-left: 15px;
    }


    .help-tip{
    position: relative;
    bottom: 0px;
    left: 5px;
    text-align: center;
    background-color: #DBDADD;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    cursor: default;
}

.help-tip:before{
    content:'?';
    font-weight: bold;
    color:#231f20;
}

.help-tip:hover p{
    display:block;
    transform-origin: 100% 0%;

    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 100;

}

.help-tip p{ 
    display: none;
    text-align: left;
    background-color: #1E2021;
    padding: 20px;
    width: 300px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    right: -4px;
    color: #FFF;
    font-size: 13px;
    line-height: 1.4;
}

.help-tip p:before{
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
    border-bottom-color:#1E2021;
    right:10px;
    top:-12px;
}

.help-tip p:after{
    width:100%;
    height:40px;
    content:'';
    position: absolute;
    top:-40px;
    left:0;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
    0% { 
        opacity:0; 
        transform: scale(0.6);
    }

    100% {
        opacity:100%;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}

.fileinput {
        display: inline-block;
        margin-bottom: 9px;
    }
    .fileinput .thumbnail {
        display: inline-block;
        margin-bottom: 5px;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
    }
    .fileinput .thumbnail>img {
        max-height: 100%;
    }
    .fileinput .btn {
        vertical-align: middle;
    }
    .fileinput-exists .fileinput-new, .fileinput-new .fileinput-exists {
        display: none;
    }
    .btn-file>input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        font-size: 23px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        direction: ltr;
    }
</style>


