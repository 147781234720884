import Vue from 'vue'
import Vuex from 'vuex'

import HelpModalStatus from './modules/helpModalStatus'
import locale from './modules/locale'
import billinkOauth from './modules/billinkOauth'
import disputeOauth from './modules/disputeOauth'
import disputeInfo from './modules/disputeInfo'
import loader from './modules/loader'
import blacklist from './modules/blacklist'
import ordersFilters from './modules/ordersFilters'
import orderPage from "./modules/orderPage";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
	  HelpModalStatus,
	  locale,
	  disputeOauth,
	  billinkOauth,
	  disputeInfo,
	  loader,
	  blacklist,
	  ordersFilters,
		orderPage
  }
})
