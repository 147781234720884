import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=26ae3597&scoped=true&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=26ae3597&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ae3597",
  null
  
)

export default component.exports