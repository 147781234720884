export default class FileHandler 
{
    constructor (binaryData = []) 
    {
        this.binaryData = []
        this.binaryData.push(binaryData)
    }

    objectToUrl ()
    {
        return window.URL.createObjectURL(new Blob(this.binaryData))
    }

    createLinkDownload (nameFile = '')
    {
        let link = document.createElement('a')
        link.href = this.objectToUrl()
        link.setAttribute('download', nameFile)
        return link
    }
}