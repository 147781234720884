<template>
    <div class="card">
            <h4 class="card-header">{{ activeLanguage.title }}</h4>
                <div class="card-body">
                    <p>{{ activeLanguage.text }}</p><br />
                    <table style="width: 100%">
                        <tr>
                            <td colspan="2">
                                <strong>{{ activeLanguage.helpdesk.title }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ activeLanguage.helpdesk.tel.prop }}</td><td>{{ activeLanguage.helpdesk.tel.val }}</td>
                        </tr>
                        <tr>
                            <td>{{ activeLanguage.helpdesk.email.prop }}</td><td><a href="" class="btn btn-sm">{{ activeLanguage.helpdesk.email.val }}</a></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <br /><strong>{{ activeLanguage.hours.title }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ activeLanguage.hours.wdProp }}</td><td>{{ activeLanguage.hours.wdVal }}</td>
                        </tr>
                        <tr>
                            <td>{{ activeLanguage.hours.sat }}</td><td>{{ activeLanguage.hours.status }}</td>
                        </tr>
                        <tr>
                            <td>{{ activeLanguage.hours.sun }}</td><td>{{ activeLanguage.hours.status }}</td>
                        </tr>
                    </table>
                        </div>
                    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            'activeLanguage': 'getHelpDeskLanguage'
        })
    }
}
</script>