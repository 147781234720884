import checks from '../checks'

const blacklist = () => import('../../views/blacklist/Blacklist')
const checklogs = () => import('../../views/checkslogs/ChecksLogs')
const nps = () => import('../../views/nps/NPS')

export default [
    {
        path: '/blacklist',
        name: 'blacklist',
        component: blacklist,
        beforeEnter: (to, from, next) =>  checks.manageOrdersCheck(to, from, next)
    },
    {
        path: '/check-logs',
        name: 'checklogs',
        component: checklogs,
        beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
    },
    {
        path: '/nps',
        name: 'nps',
        component: nps,
        beforeEnter: (to, from, next) =>  checks.manageOrdersCheck(to, from, next)
    }
]
