const state = {
  currentPageState: 1
}

const mutations = {
  'SET_CURRENT_PAGE'(state, page) {
    state.currentPageState = page
  }
}

const actions = {
  setCurrentPage({commit}, page) {
    commit('SET_CURRENT_PAGE', page)
  }
}

const getters = {
  getCurrentPage: state => state.currentPageState
}

export default {state,  mutations, actions, getters}