export default class BlackListService {
    static _getBlackListAll(page = 1, params = {}) {
        return billinkAxios.get(`/app/blacklist/show`, { params })
    }
    static _saveBlackList(data = {}) {
         return billinkAxios.post('/app/blacklist/save', data)
    }
    static _removeBlackList(ids = []) {
         return billinkAxios.delete('/app/blacklist/delete', { data: { ids } } )
    }
    static _updateBlackList(data = {}) {
        return billinkAxios.put('/app/blacklist/save', data)
    }
}