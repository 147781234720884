<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <form class="col-md-12" @submit.prevent="onSettingFormSubmit">
                            <h1>{{ activeLanguage.passTable.title }}</h1>
                            <p>{{ activeLanguage.passTable.text }}<br /><br /></p>
                            <div class="form-group row">
                                <label for="inputPassword3" class="col-sm-3 col-form-label">{{ activeLanguage.passTable.pass }}*</label>
                                <div class="col-sm-5">
                                    <input v-model="password" type="text" class="form-control" id="inputPassword3">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword4" class="col-sm-3 col-form-label">{{ activeLanguage.passTable.passRep }}*</label>
                                <div class="col-sm-5">
                                    <input v-model="repassword" type="text" class="form-control" id="inputPassword4">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label"></label>
                                <div class="col-sm-9">
                                    <button type="submit" class="btn">{{ activeLanguage.passTable.saveBtn }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <h4 class="card-header">{{ activeLanguage.help.title }}</h4>
                        <div class="card-body">
                            <p>{{ activeLanguage.help.text }}</p><br />
                            <table style="width: 100%">
                                <tr>
                                    <td colspan="2">
                                        <strong>{{ activeLanguage.help.helpdesk.title }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.help.helpdesk.tel.prop }}</td><td>{{ activeLanguage.help.helpdesk.tel.val }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.help.helpdesk.email.prop }}</td><td><a href="" class="btn btn-sm">{{ activeLanguage.help.helpdesk.email.val }}</a></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <br /><strong>{{ activeLanguage.help.hours.title }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.help.hours.wdProp }}</td><td>{{ activeLanguage.help.hours.wdVal }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.help.hours.sat }}</td><td>{{ activeLanguage.help.hours.status }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.help.hours.sun }}</td><td>{{ activeLanguage.help.hours.status }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"

	export default {
		name: "ChangePassword",
        data() {
			return {
				password: '',
                repassword: ''
            }
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getSettingsLanguage'
			})
		},
        mounted() {
			this.$store.dispatch('destroyBillinkToken')
        },
        methods: {
	        onSettingFormSubmit() {
	        	if (this.password === this.repassword) {
	        		let data = {
				        password: this.password
                    }
                    billinkAxios
                        .post(`app/password/new?key=${this.$route.query.key}`, data)
                        .then(({data}) => {
                        	console.log(data);
	                        this.$router.push('/')
                        })
                        .catch(err => console.error(err))
                } else {
			        EventBus.$emit('showAlertModal', 'Passwords are unequal')
                }
	        }
        }
	}
</script>

<style scoped>

</style>