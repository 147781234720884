<template>
    <div class="row d-flex">
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.company_name}*:`" v-model="data.company_name"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.trade_name}*:`" v-model="data.trade_name"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.coc_number}*:`" v-model="data.coc_number"/>
            </div>
            <div class="form-group d-flex">
                <input type="text" class="form-control col-md-8" required :placeholder="`${language.street}*:`" v-model="data.street"/>
                <input type="text" class="form-control col-md-3" required :placeholder="`${language.house_number}*:`" v-model="data.house_number"/>
            </div>
            <div class="form-group d-flex">
                <input type="text" class="form-control col-md-3" required :placeholder="`${language.postal_code}*:`" v-model="data.postal_code"/>
                <input type="text" class="form-control col-md-8" required :placeholder="`${language.city}*:`" v-model="data.city"/>
            </div>
            <div class="form-group d-flex">
                <input type="text" class="form-control col-md-5" required :placeholder="`${language.contact_person_fname}*:`" v-model="data.contact_person"/>
                <input type="text" class="form-control col-md-5" required :placeholder="`${language.last_name}*:`" v-model="data.last_name"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.emailaddress_for_administration}*:`" v-model="data.email_admin"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.name_webshop}*:`" v-model="data.name_webshop"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.emailaddress_for_customers}*:`" v-model="data.email_customer"/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.legally_valid_representative}*:`" v-model="data.legally_valid"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.iban}*:`" v-model="data.iban"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.bic}*:`" v-model="data.bic"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.bankaccount_holder}`" v-model="data.bankaccount"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.vat_number}`" v-model="data.vat"/>
            </div>
            <div class="form-group">
                <select class="custom-select" v-model="data.platform">
                    <option value="" selected disabled hidden>{{ language.select_platform }}</option>
                    <option v-for="item in list_platforms" :value="item">{{ item }}</option>                                      
                </select>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.telephone_number_for_adminstration}*:`" v-model="data.tel_admin"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.url_of_webshop}*:`" v-model="data.url_webshop"/>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" required :placeholder="`${language.telephone_number_for_customers}*:`" v-model="data.tel_customer"/>
            </div>
        </div>
        <div class="col-md-12 d-end">
            <button class="btn" @click="onSubmit">{{ language.btn }}</button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        language: {
            type: Object
        }
    },
    data() {
        return {
            data: {
                platform: "",
                garant: "30 dagen"
            },
            list_platforms: [
                "Lightspeed",
                "Ccv Shop",
                "Magento",
                "Open Cart",
                "PrestaShop",
                "WooCommerce",
                "Luondo",
                "MyShop",
                "Gratis Webshop Beginnen",
                "LogiVert",
                "Shoptrader",
                "API",
                "Other"
                ]
        }
    },
    methods: {
        onSubmit(e) {
            this.$emit('onsubmit', this.data)
        },
        clear() {
            this.data = {}
        }
    }
}
</script>
<style lang="scss">
    .padd-0 {
        padding: 0;
    }
    .d-flex {
        display: flex;
        justify-content: space-between;
    }
    .d-end {
        display: flex;
        justify-content: flex-end;
    }
    .m-left-45 {
        margin-left: 45px;
    }
</style>