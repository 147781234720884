import axios from "axios"
import store from "../../store/index"
import app from '../../main'
import router from "../../routes/index"
import locale from '../../store/modules/locale'

const billinkAxios = axios.create({
    baseURL: process.env.VUE_APP_BILLINK_BASE_URL
  }),
  newbillinkAxios = axios.create({
    baseURL: process.env.VUE_APP_NEW_BILLINK_BASE_URL
  }),
  disputeAxios = axios.create({
    baseURL: process.env.VUE_APP_DISPUTE_BASE_URL
  }),
  statisticsAxios = axios.create({
    baseURL: process.env.VUE_APP_BILLINK_BASE_URL
  }),
  checkAxios = axios.create({
    baseURL: process.env.VUE_APP_CHECK_BASE_URL
  }),
  shoppingAxios = axios.create({
    baseURL: process.env.VUE_APP_SHOPPING_BASE_URL
  }),
  mfaAxios = axios.create({
    baseURL: process.env.VUE_APP_MFA_BASE_URL
  }),
  orderAxios = axios.create({
    baseURL: process.env.VUE_APP_ORDER_BASE_URL
  }),
  standartAxios = axios.create(),
  trackerAxios = axios.create({
    baseURL: process.env.VUE_APP_TRACKER_BASE_URL
  })

let authCounter = 0
let errorCounter = 0
const MAX_RETRY = 5
let isLoaderActive = false
let isError = false

function isTokenExpired() {
  const token = store.getters.getBillinkToken;

  if (!token || !token.expires_at) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= token.expires_at;
}

function setupRequestInterceptors(axiosInstance) {
  axiosInstance.interceptors.request.use(request => {
    app.$Progress.start();
    if (!isLoaderActive) {
      store.dispatch('startLoader');
      isLoaderActive = true;
    }

    if (isTokenExpired()) {
      store.dispatch('destroyBillinkToken')
      store.dispatch('endLoader');
      return Promise.reject({ response: { status: 400, data: 'Token expired' } });
    }

    let { access_token, details } = store.getters.getBillinkToken;
    if (access_token) {
      if (request.headers['Authorization'] !== 'smthWentWrong') {
        request.headers['Authorization'] = `Bearer ${access_token}`
      }
      request.headers['id'] = details.id
    }
    return request;
  });
}

[newbillinkAxios, disputeAxios, statisticsAxios, checkAxios, standartAxios, mfaAxios, orderAxios].forEach(setupRequestInterceptors);

billinkAxios.interceptors.request.use(request => {
  app.$Progress.start();
  if (!isLoaderActive) {
    store.dispatch('startLoader');
    isLoaderActive = true
  }

  let { access_token } = store.getters.getBillinkToken;
  if (access_token) {
    request.headers['Authorization'] = `Bearer ${access_token}`
  }
  return request
})

trackerAxios.interceptors.request.use(request => {
  if (isTokenExpired()) {
    store.dispatch('destroyBillinkToken')
    store.dispatch('endLoader');
    return Promise.reject({ response: { status: 400, data: 'Token expired' } });
  }
  const { access_token } = store.getters.getBillinkToken
  if (access_token) {
    request.headers['Authorization'] = `Bearer ${access_token}`
  }
  request.headers['Accept'] = 'application/json';
  return request
})

shoppingAxios.interceptors.request.use(request => {
  app.$Progress.start()
  store.dispatch('startLoader')

  let access_token = process.env.VUE_APP_SHOPPING_TOKEN
  if (access_token) {
    if (request.headers['Authorization'] !== 'smthWentWrong') { request.headers['Authorization'] = `Bearer ${access_token}` }
  }
  return request
})


billinkAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

statisticsAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

newbillinkAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

disputeAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

checkAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

standartAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

shoppingAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

mfaAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

orderAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

trackerAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errHandler(error)
)

export default { disputeAxios, billinkAxios, statisticsAxios, newbillinkAxios, checkAxios, standartAxios, shoppingAxios, mfaAxios, orderAxios, trackerAxios }

let resHandler = (response) => {
  store.dispatch('endLoader');
  isLoaderActive = false

  app.$Progress.finish()
  return response
},  errHandler = (error) => {
  let isLoginPage = (window.location.pathname === "/login");
  isError = !isLoginPage;
  let { response: { status } } = error
  app.$Progress.fail()

  if ( status === 400 ) {
    isLoaderActive = false
    isError = false
    store.dispatch('endLoader');
  }

  if (status === 400 && error.response.data === 'Token expired') {
    store.dispatch('destroyBillinkToken');
    router.push({ path: '/login' });
    return;
  }

  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    let redirectUrl = !window.location.pathname.includes("/order") ? '/login' : '/';
    isLoaderActive = false
    isError = false
    store.dispatch('endLoader');
    if (!window.location.pathname.includes("/order")) {store.dispatch('destroyBillinkToken')}
    return router.push({ path: redirectUrl })
  }

  if ( status === 404 ) {
    isLoaderActive = false
    store.dispatch('endLoader');
  }

  if (status === 500) {
    console.log(error.config)
    if (++errorCounter >= MAX_RETRY) {
      isLoaderActive = false
      isError = false
      errorCounter = 0
      store.dispatch('endLoader');
      router.go(-1)
      EventBus.$emit('showAlertModal', locale.state.locale.respErrorMsg)
    } else {
      error.config.baseURL = error.config.url
      try {
        setTimeout(() => standartAxios.request(error.config), 0)
      } catch(e) {
        if (e instanceof RangeError) {
          console.log(e)
        }
      }
    }
  }

  if (error.response.data.error === 'Bad token') {
    isLoaderActive = false
    store.dispatch('endLoader');
    store.dispatch('destroyBillinkToken')
    location.reload(true)
    return router.push({ path: '/login' })
  }

  if (error.response.data.access_denied === 'You have read-only access.') {
    EventBus.$emit('showAlertModal', locale.state.locale.respErrorReadOnly)
  }

  return Promise.reject(error);
}