<template>
    <div
        class="collapse show"
        id="help-panel"
        v-if="show"
    >
        <div class="help">
            <a class="close" href="#" @click.prevent="close"><i class="fa fa-times" aria-hidden="true"></i></a>
            <h2>{{ activeLanguage.title }}</h2>
            <hr />
            <div id="accordion" class="accordion">
                <div class="item" v-for="item in data">
                    <a href="#" @click.prevent="toggleFaq(item)">
                        <strong>{{item.question}}</strong>
                    </a>
                    <div class="collapse show" v-show="item.active">
                        <span class="mb-3" v-for="text in item.answer">
                            {{ text }}
                        </span>
                        <ul v-if="!!item.ul" v-for="t in item.ul" style="padding-left: 17px;">
                            <li>{{ t }}</li>
                        </ul>
                        <ol v-if="!!item.ol" style="padding: 17px;">
                            <li v-for="t in item.ol">{{ t }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex";

export default {
	name: 'help-sidebar',
	props:{
		activeLanguage: {
			type: Object,
			required: true
		}
	},
    data () {
        return {
           show: false,
           data: [] 
        }
    },
    mounted() {
        this.data = this.activeLanguage.faqArr
    },
    methods: {
        close() {
            this.show = false
        },
        open() {
            this.show = true
        },
	    toggleFaq(item) {
            item.active = !item.active
        }
    }
}
</script>

<style scoped>
    .help {
        overflow: auto;
    }
    @media (max-width:460px) {
        .help {
            width: 100vw;
            overflow: scroll;
        }
    }
</style>
