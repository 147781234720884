<template>
    <nav class="topbar fixed-top" v-if="showHeader">
        <div class="float-right dropdown show" v-click-outside="closeNavDropdown">
            <button
                class="dropdown-btn"
                type="button"
                id="moremenubutton"
                @click="showNavDropdown = !showNavDropdown"
            >
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <transition name="colapse">
                <div
                    class="dropdown-menu dropdown-menu-right show"
                    v-show="showNavDropdown"
                    @click="showNavDropdown = !showNavDropdown"
                >
                    <router-link
                        class="dropdown-item d-lg-none"
                        to="/"
                    >
                        <div>
                            <img src="/img/icons/dashboard.svg">
                            {{ activeLanguage.headerDropdown.dashboard }}
                        </div>
                    </router-link>
                    <router-link
                        class="dropdown-item d-lg-none"
                        to="/orders"
                    >
                        <div>
                            <img src="/img/icons/orders.svg">
                            {{ activeLanguage.headerDropdown.orders }}
                        </div>
                    </router-link>
                    <router-link
                        class="dropdown-item d-lg-none"
                        to="/invoices"
                    >
                        <div>
                            <img src="/img/icons/invoices.svg">
                            {{ activeLanguage.headerDropdown.invoices }}
                        </div>
                    </router-link>
                    <router-link
                        class="dropdown-item d-lg-none"
                        to="/statistics"
                    >
                        <div>
                            <img src="/img/icons/statistics.svg">
                            {{ activeLanguage.headerDropdown.statistics }}
                        </div>
                    </router-link>
                    <router-link
                            class="dropdown-item d-lg-none"
                            to="/dispute/employee/group/show"
                    >
                        <div>
                            <img src="/img/icons/dispute.svg">
                            {{ activeLanguage.headerDropdown.dispute }}
                        </div>
                    </router-link>
                    <div class="dropdown-divider d-lg-none"></div>
                    <a class="dropdown-item d-lg-none" href="#"
                       @click.prevent="selectLanguage(activeLanguage.name === 'nederlands' ? 'english' : 'dutch')">
                        <i class="fa fa-flag fa-fw" aria-hidden="true"></i>
                        {{ activeLanguage.headerDropdown.language }}:
                        <span>
                        <img :src="`/img/flags/${ activeLanguage.name === 'nederlands' ? 'dutch' : 'english' }.svg`" class="flag flag-xs">
                    </span>
                    </a>
                    <router-link class="dropdown-item" to="/user/override" v-if="manageClientsPermission">
                        <i class="fa fa-sign-out fa-fw" aria-hidden="true"></i>
                        user override
                    </router-link>
                    <!-- <router-link class="dropdown-item" to="/account">
                        <i class="fa fa-gear" aria-hidden="true"></i>
                        Account
                    </router-link> -->
                    <router-link class="dropdown-item" to="/settings">
                        <i class="fa fa-gear" aria-hidden="true"></i>
                        {{ activeLanguage.headerDropdown.settings }}
                    </router-link>
                    <a class="dropdown-item" href="#" @click.prevent="logOut">
                        <i class="fa fa-sign-out fa-fw" aria-hidden="true"></i>
                        {{ activeLanguage.headerDropdown.signOut }}
                    </a>
                </div>
            </transition>
        </div>
        <div class="logo">
            <router-link to="/"><img :src=logoLink></router-link>
        </div>
        <div class="navbar-container">
            <div style="position: relative;" v-if="showTestSign">
                <div class="header-test-sign">
                    testing
                </div>
            </div>
            <div class="container">
                <ul class="nav">
                    <li class="nav-item d-none d-lg-block">
                        <a class="nav-link" href="#" @click.prevent="toggleSearchBar">
                            <img src="/img/icons/search.svg">
                            {{ activeLanguage.searchOrder }}
                        </a>
                    </li>
                    <li class="nav-item d-none d-lg-block" v-if="disputeExists">
                        <router-link class="nav-link" :to="{ name: 'show.dispute.groups', params: { filterTasks: true} }">
                            <img src="/img/icons/tasks.svg">
                            {{ activeLanguage.tasks }}
                            <sup><span class="badge">{{tasks}}</span></sup>
                        </router-link>
                    </li>
                    <li
                        v-if="!manageOrdersPermission && showInvInfo"
                        class="nav-item d-none d-xl-block"
                        @click="showInvPopUp"
                        v-verify-scope-view-parent="userView_parent_orders"
                    >
                        <div class="nav-link" style="cursor: pointer;">
                            <img src="/img/icons/payout.svg" class="payout-img">
                            <div class="payout">
                                {{ activeLanguage.weeklyPayout }}
                                <span>€ {{ invInfo.total | numberFormat(2, ',', '.') }}</span>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item d-none d-lg-block show">
                        <a href="#" class="nav-link" id="languagebutton" @click.prevent="toggleLangPanel">
                            <img src="/img/icons/language.svg">
                            {{ activeLanguage.name }}
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </a>

                        <div class="dropdown-menu language-menu show" v-if="showLangPanel"
                             @click-outside="closeLangPanel" v-click-outside="closeLangPanel">
                            <a href="#" class="dropdown-item" @click.prevent="selectLanguage('english')">
                                <img src="/img/flags/english.svg" class="flag">
                                {{ activeLanguage.selectLanguage.en }}
                            </a>
                            <a href="#" class="dropdown-item" @click.prevent="selectLanguage('dutch')">
                                <img src="/img/flags/dutch.svg" class="flag">
                                {{ activeLanguage.selectLanguage.nl }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <transition name="collapse">
                <div
                    id="search-bar"
                    class="collapse search-bar show"
                    v-if="showSearchBar"
                    v-click-outside="closeSearchBar"
                >
                    <div class="container">
                        <form class="form-inline" @submit.prevent="onSearchSubmit">
                            <input
                                class="form-control mr-sm-12"
                                v-model="searchQuery"
                                type="text"
                                :placeholder="activeLanguage.searchInput"
                                style="width: 300px; margin-right: 7px"
                            >
                            <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                    </div>
                </div>
            </transition>
            <transition name="collapse">
                <div
                    id="notification-bar"
                    class="collapse notification-bar show"
                    v-if="showNotificationBar"
                >
                <div class="container notification">
                    <div class="notificationText" @click="closeNotificationBar">
                        <router-link to="/settings">{{ activeLanguage.headerNotification.text }}</router-link>
                    </div>
                    <div class="notificationBtn" @click="closeNotificationBar">
                        <i class="fas fa-times-circle"></i>
                        <p>{{ activeLanguage.headerNotification.dismiss }}</p>
                    </div>
                </div>
                </div>
            </transition>
        </div>
        <progressBar />
        <InvoicePopUp
            :activeLanguage="activeInvLanguage"
            :invoices="invInfo.invoices"
            ref="InvoicePopUp"
        />
    </nav>
</template>

<script>
	import {mapGetters} from  "vuex"
    import progressBar from './ProgressBar'
    import InvoicePopUp from './InvoicePopUp'
    import moment from 'moment'
    import queryString from 'query-string'

	export default {
		name: 'header-top',
		props: {
			activeLanguage: {
				type: Object,
				required: true,
			},
            tasks: {
	            type: Number,
	            required: true,
            },
			disputeExists: {
				type: Boolean,
				required: true
			}
		},
		data() {
			return {
				showTestSign: process.env.VUE_APP_TEST_ENV || false,
				showLangPanel: false,
				showNavDropdown: false,
				showSearchBar: false,
				searchQuery: '',
                invInfo: {
					invoices: [],
                    total: 0.00,
	                date: ''
                },
                showInvInfo: false,
                logoLink: `${process.env.VUE_APP_CDN_BASE_URL}svg/billink-logo-whitemono.svg`,
                showNotificationBar: false,
                showHeader: false
			}
		},
        created(){
            this.showHeader = localStorage.getItem('Btoken') ? true : false
        },
		mounted() {
			if (!this.manageOrdersPermission && !this.isResellerUser && !!this.btoken.access_token) {
				this.getUsersInv()
			}
            setTimeout(() => {
                this.checkNotificationBar()
            }, 500)
		},
		methods: {
			showInvPopUp() {
				if (!!this.invInfo.invoices.length) {
					this.$refs['InvoicePopUp'].open()
				}
			},
			getUsersInv() {
				newbillinkAxios
					.get('/client-invoice/list/clients')
					.then(({data}) => {
						let client = data.filter(client => client.client_id === this.userInfo.id)
                        if (!!client.length) {
	                        this.invInfo.total = client[0].total_sum
                            this.showInvInfo = client[0].total_sum > 0 ? true : false
	                        this.getInvInfo()
                        }
					})
					.catch(err => console.error(err))
            },
			getInvInfo() {
				let params = { clients: [this.userInfo.id] }
				newbillinkAxios
					.get('/client-invoice/list/orders', { params })
					.then(({data}) => {
						this.invInfo.invoices = data
					})
					.catch(err => console.error(err))
            },
			logOut() {
				this.$store.dispatch('destroyBillinkToken')
                this.$router.push('/login')
            },
			toggleLangPanel() {
				this.showLangPanel = !this.showLangPanel
			},
			selectLanguage(lang) {
				this.showLangPanel = false
				this.$store.dispatch('changeLanguage', lang);
			},
			toggleSearchBar() {
				this.showSearchBar = !this.showSearchBar
                this.searchQuery = ''
			},
			onSearchSubmit() {
        let params = {
          filter: {
            invoice: this.searchQuery
          },
          page: 1
        }

        billinkAxios
            .get(`app/order-list`, { params })
            .then(({ data }) => {
              let order = data.order_list;
              if (order.length) {
                this.$router.push(`/order/${order[0].invoice}`)
              } else {
                EventBus.$emit('showAlertModal', this.activeInvLanguage.popupErr )
              }
              this.toggleSearchBar();
              this.searchQuery = '';
            })
            .catch(error => console.error(error))
            .then(() => {
            })
			},
			closeLangPanel() {
				this.showLangPanel = false
			},
			closeSearchBar() {
				this.showSearchBar = false
			},
			closeNavDropdown() {
				this.showNavDropdown = false
			},
            closeNotificationBar() {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                let data = {
                    date: moment().add(1, 'M').format('YYYY-MM-DD')
                }

                billinkAxios
                    .post(`app/client/multifactor/dismiss-notification-till`, queryString.stringify(data), { headers })
                    .then(() => {
                        this.showNotificationBar = false
                    })
                    .catch(error => console.error(error))
            },
            checkNotificationBar() {
                let currentDate = moment().format('YYYY-MM-DD')

                if (this.btoken.mfa.multifactor_enabled) {
                        this.showNotificationBar = false
                    } else {
                        if (this.btoken.mfa.dismiss_multifactor_notification_till && this.btoken.mfa.dismiss_multifactor_notification_till >= currentDate) {
                            this.showNotificationBar = false
                            } else {
                                this.showNotificationBar = true
                            }
                    }
            }
		},
        components: {
	        progressBar,
	        InvoicePopUp
        },
		computed: {
			...mapGetters({
				manageOrdersPermission: 'userManage_orders',
				manageClientsPermission: 'userManage_clients',
				manageFinancePermission: 'userManage_finance',
				userInfo: 'getBillinkUserDetails',
				btoken: 'getBillinkToken',
				activeInvLanguage: 'getInvoiceGenerationLanguage',
				managePermission: 'getBillinkUserPermissions',
                userView_parent_orders: 'userView_parent_orders',
                isResellerUser: 'isResellerUser'
			})
		},
	}
</script>

<style scoped lang="scss">
    .header-test-sign {
        pointer-events: none;
        position: absolute;
        top: 2px;
        left: 0;
        background-color: red;
        color: #fff;
        border: 2px solid #fff;
        text-transform: uppercase;
        font-size: 32px;
        padding:0 10px;
        line-height: 60px;
        height: 60px;
        @media (max-width: 1600px) {
            display: none;
        }
    }
    .sub-menu {
        list-style-type: none;
        padding-left: 10px;
    }

    .nav-item.show {
        position: relative;
    }

    .topbar .nav-link {
        color: #ffffff !important;
    }

    .dropdown-menu-right.show {
        position: absolute;
        transform: translate3d(-123px, 62px, 0px);
        top: 0px;
        left: 2px;
        will-change: transform;
        width: 202px;
    }

    .dropdown-item {
        div {
            line-height: 21px;
            height: 21px;
            img {
                max-height: 14px;
                max-width: 18px;
                vertical-align: unset;
            }
        }
    }

    .flag-xs {
        margin-left: 3px;
        vertical-align: middle;
    }

    @media (max-width: 992px) {
        .dropdown-menu.show {
            transform: translate3d(0, 0, 0) !important;
            top: 100% !important;
            right: 0 !important;
            left: auto !important;
            margin-top: 0;
            border-top: none;
        }
    }

    @media (max-width: 400px) {
        .dropdown-menu.show {
            width: 100vw;
            height: 100vh;
            overflow: scroll;
            border: none;
            box-shadow: none;
            .dropdown-item {
                padding: 0.75rem 1.5rem;
                font-size: 16px;
            }
        }
    }

    .notification-bar {
        position: absolute;
        left: 142px;
        top: 0;
        right: 0;
        margin-top: 65px;
        background-color: #fd7e14;
        color: #fff;
    }

    .notification {
        display: flex;
        justify-content: space-between;
    }

    .notificationText {
        font-size: 16px;
        text-decoration: underline;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        
        a {
            font-weight: 700;
            color: #fff;
        }
    }

    .notificationBtn {
        display: flex;
        font-size: 16px;
        margin-top: 0.5rem;

        p {
            font-weight: 700;
            text-decoration: underline;
            margin-bottom: 0.5rem;
        }

        i {
            width: 22px;
            color: #fff;
            margin-top: 3px;
        }
    }

    .notificationBtn:hover {
        cursor: pointer;
    }
</style>