const DisputeEmployeeShow = () => import('../../views/dispute/employee/DisputeEmployeeShow.vue'),
	DisputeEmployeeDetails = () => import('../../views/dispute/employee/DisputeEmployeeDetails.vue'),
	DisputeEmployeeCreateDispute = () => import('../../views/dispute/employee/DisputeEmployeeCreateDispute.vue'),
	DisputeEmployeeShowGroups = () => import('../../views/dispute/employee/DisputeEmployeeShowGroups'),
	DisputeEmployeeCreateGroup = () => import('../../views/dispute/employee/DisputeEmployeeCreateGroup'),
	TemplateList = () => import('../../views/dispute/TemplateList'),
	Configurator = () => import('../../views/dispute/Configurator'),
	ConfiguratorSteps = () => import('../../views/dispute/ConfiguratorSteps'),
	TemplateVariables = () => import('../../views/dispute/TemplateVariables'),
	UnrecognisedMails = () => import('../../views/dispute/UnrecognisedMails'),
	AutomationRules = () => import('../../views/dispute/employee/automationRules/AutomationRules')

import checks from '../checks'

export default [
	{
		path: '/dispute/employee/group/create',
		component: DisputeEmployeeCreateGroup
	},
	{
		name: 'show.dispute.groups',
		path: '/dispute/employee/group/show',
		component: DisputeEmployeeShowGroups
	},
	{
		path: '/dispute/employee/create/:groupId',
		component: DisputeEmployeeCreateDispute
	},
	{
		path: '/dispute/employee/show/:groupId',
		component: DisputeEmployeeShow
	},
	{
		path: '/dispute/employee/show/:groupId/details/:disputeId',
		component: DisputeEmployeeDetails
	},
	{
		path: '/dispute/template/list',
		component: TemplateList,
		beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
	},
	{
		path: '/dispute/employee/mail',
		component: UnrecognisedMails,
	},
	{
		path: '/dispute/configurator',
		component: Configurator,
		beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
	},
	{
		path: '/dispute/configurator/variables',
		component: TemplateVariables,
		beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
	},
	{
		path: '/dispute/configurator/steps',
		component: ConfiguratorSteps,
		beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
	},
	{
		path: '/dispute/employee/rules',
		component: AutomationRules,
		beforeEnter: (to, from, next) => checks.manageOrdersCheck(to, from, next)
	},
]