import lowercase from './modules/lowercase'
import numberFormat from './modules/numberFormat'
import sliceTheString from './modules/sliceTheString'


export default {
	install(Vue) {
		Vue.filter('lowercase', lowercase)
		Vue.filter('numberFormat', numberFormat)
		Vue.filter('sliceTheString', sliceTheString)
	}
}