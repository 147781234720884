<template>
    <b-modal
        @cancel="close"
        hide-footer
        size="lg"
        v-model="showModal"
        header-class="card-header pause"
        :title="activeLanguage.popupTitle"
    >
        <table class="invoices-table table" style="margin-bottom: 0;">
            <thead>
            <tr>
                <th>{{ activeLanguage.inv }}</th>
                <th>{{ activeLanguage.amount }}</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="invoice in visiableInvoices"
                @click="goToOrder(invoice)"
            >
                <td>{{ invoice.invoice }}</td>
                <td>
                    <span>€</span>
                    {{ invoice.amount | numberFormat(2)}}
                </td>
            </tr>
            </tbody>
            <tfoot v-if="invoices.length > 20 && showShowMoreBtn">
                <tr>
                    <td colspan="2">
                        <button class="btn" type="button" @click="showMore">
                            {{ activeLanguage.showMore }}
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </b-modal>
</template>

<script>
	export default {
		name: "InvoicePopUp",
        props: {
	        activeLanguage: {
		        type: Object,
		        required: true,
		        default: function () {
			        return {}
		        }
	        },
            invoices: {
	        	type: Array,
                required: true,
                default: function () {
                    return []
                }
            }
        },
        data() {
			return {
				showModal: false,
				visiableInvoices: [],
				showShowMoreBtn: true
            }
        },
        methods: {
	        close() {
	        	this.showModal = false
            },
            open() {
	        	this.visiableInvoices = this.invoices.slice(0, 20)
	        	this.showModal = true
            },
            showMore() {
                this.visiableInvoices = this.invoices
	            this.showShowMoreBtn = false
            },
            goToOrder({invoice}) {
	        	this.$router.push(`/order/${invoice}`)
                this.close()
            }
        }
	}
</script>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
<style>
    .modal-header.card-header.pause .modal-title,
    .modal-header.card-header.pause .close{
        color: #ffffff !important;
        opacity: 1;
    }
</style>