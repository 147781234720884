<template>
    <div class="login">
        <div class="header">
            <img :src=logoLink>
        </div>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-xl-3 col-sm-6" v-if="!maintenanceLogOff && !trunkDisabled">
                    <h2>{{ activeLanguage.title }}</h2>
                    <form class="login-form" @submit.prevent="onLoginSubmit">
                        <div class="input-group mb-2 mb-sm-0" >
                            <div class="input-group-addon">
                                <i class="fa fa-fw fa-user" aria-hidden="true"></i>
                            </div>
                            <input
                                v-model="form.username"
                                type="text"
                                class="form-control"
                                id="username"
                                :placeholder="activeLanguage.username"
                                name="username"
                            >
                        </div>
                        <div class="input-group mb-2 mb-sm-0">
                            <div class="input-group-addon">
                                <i class="fa fa-fw fa-key" aria-hidden="true"></i>
                            </div>
                            <input
                                v-model="form.password"
                                name="password"
                                type="password"
                                class="form-control"
                                id="password"
                                :placeholder="activeLanguage.password"
                            >
                        </div>
                        <div class="input-group mb-2 mb-sm-0" v-if="showCodeField">
                            <div class="input-group-addon">
                                <i class="fas fa-fw fa-hashtag" aria-hidden="true"></i>
                            </div>
                            <input
                                v-model="form.code"
                                name="code"
                                type="text"
                                class="form-control"
                                id="code-search"
                                data-lpignore="true"
                                autocomplete="off"
                                :placeholder="activeLanguage.codePlaceholder"
                            >
                        </div>
                        <div class="input-group mb-2 mb-sm-0" style="paddingBottom: 0px" v-show="showErrorMessageText">
                            <p class="errMsgText" :style="codeErrorMessageText === activeLanguage.newCodeSent ? {color: 'white'} : {}">{{ codeErrorMessageText }}</p>
                        </div>
                        <div class="lower" :style="showErrorMessageText ? {marginTop: '10px'} : ''">
                            
                            <button type="submit" class="btn" style="width: 100%">{{ activeLanguage.logBtn }}</button>
                            <div class="loginLinks">
                                <a @click.prevent="onForgotPassword" href="" class="forgot-link" style="margin-left: 0px" v-show="!showCodeField">{{ activeLanguage.forgotBtn }}</a>
                                <a @click.prevent="onResendCode" href="" class="forgot-link" v-show="showCodeField">{{ activeLanguage.resendCodeBtn }}</a>
                                <a href="https://www.billink.nl/zakelijk/pakketten" class="forgot-link" style="margin-right: 0px">{{ activeLanguage.register }}</a>
                            </div>
                        </div>
                    </form>
                    <transition name="collapse">
                        <form
                            @submit.prevent="onEmailSubmit"
                            class="collapse forgot-password show"
                            id="forgot-password"
                            v-if="showForgotPasswordForm"
                        >
                            <div class="inner">
                                <p style="margin-bottom: 10px">{{ activeLanguage.sendTxt }}</p>
                                <div class="form-inline">
                                    <div class="form-group pr-sm-3">
                                        <input
                                            v-model="email"
                                            type="text"
                                            class="form-control"
                                            id="email"
                                            :placeholder="activeLanguage.email"
                                            name="email"
                                        >
                                    </div>
                                    <button type="submit" class="btn">{{ activeLanguage.sendBtn }}</button>
                                </div>
                            </div>
                        </form>
                    </transition>
                    <div class="mb-2 mb-sm-0" v-if="maintenanceLogOn">  
                        <div style="margin-top: 30px">
                            <div class="alert alert-danger alert-dismissible logOn">
                                <div class="logOnHead">
                                    <div class="alert-heading">
                                        <i class="fas fa-exclamation-triangle"></i>
                                        <strong class="mx-2">Let op!</strong>
                                    </div>
                                    <a class="close" href="#" @click.prevent="maintenanceLogOn = false"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </div>
                                <div class="logOnBody">
                                    {{ maintenanceMessage }}
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>

                <div class="col-xl-6 col-sm-6" v-if="maintenanceLogOff">
                    <div class="alert alert-danger alert-dismissible logOf">
                        <div class="logOfHeader">
                            <h4 class="alert-heading" style="font-size: 20px;"><i class="fas fa-exclamation-triangle"></i> Inloggen is momenteel niet mogelijk!</h4>
                            <a class="close" href="#" @click.prevent="maintenanceLogOff = false"><i class="fa fa-times" aria-hidden="true"></i></a>
                        </div>
                        <div class="logOfMain">
                            <p v-if="!trunkDisabled">{{ maintenanceMessage }}</p>
                            <p v-if="trunkDisabled">{{ activeLanguage.trunkDisabled }}</p>
                            <hr v-if="!trunkDisabled">
                            <p class="mb-0" v-if="!trunkDisabled">We verwachten dat het probleem opgelost is voor: {{ maintenanceDateTo }}</p>
                        </div>
                    </div>
                </div>


                <LoginModalError 
                    ref="loginModalError"
                    :errorMessage="codeErrorMessageText"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from  "vuex"
    import LoginModalError from '../components/login/modals/LoginModalError'
    import store from '@/store/index'

	export default {
		name: 'login',
		data() {
			return {
				showForgotPasswordForm: false,
                showCodeField: false,
                form : {
	                username: '',
	                password: '',
                    code: ''
                },
				email: '',
                logoLink: `${process.env.VUE_APP_CDN_BASE_URL}svg/billink-logo-whitemono.svg`,
                codeErrorMessageText: '',
                showErrorMessageText: false,
                maintenanceLogOn: false,
                maintenanceLogOff: false,
                maintenanceMessage: '',
                maintenanceDateTo: null,
                trunkDisabled: false
			}
		},
        mounted(){
            this.checkMaintenance()
        },
		computed: {
			...mapGetters({
				btoken: 'getBillinkToken',
				activeLanguage: 'getLoginLanguage',
                role: 'userManage_orders',
                codeMessage: 'getCodeMessage',
                codeErrorMessage: 'getCodeErrorMessage'
			})
		},
        watch: {
            codeErrorMessage: function (newVal, oldVal) {
                if(newVal !== oldVal) {
                    if(newVal.includes('Attempts left')) {
                        let attempts = newVal[newVal.indexOf(':') + 2]
                        this.codeErrorMessageText = `${this.activeLanguage.attemptsLeft + attempts}`
                        this.showErrorMessageText = true
                    } else if (newVal.includes('Limit of attempts')){
                        this.codeErrorMessageText = this.activeLanguage.attemptsReached
                        this.showErrorMessageText = true
                    } else {
                        this.codeErrorMessageText = this.codeErrorMessage
                        this.showErrorMessageText = false
                    }
                }
            }
        },
		beforeRouteEnter (to, from, next) {
			!!localStorage.getItem('Btoken') ? next('/') : next()
		},
		methods: {
			onLoginSubmit() {
				this.$store.dispatch('billinkLogIn', this.form)
					.then(() => {
                        if (this.codeMessage.length) {
                            this.showCodeField = true

                        } else {
                            this.showCodeField = false
                            this.disputeLogin()
                        }
                    })
					.catch(err => {
                        console.error(err);
                        let { status } = err.response
                        if (status === 400) {
                            if(!err.response.data.error.includes('Attempts left') && !err.response.data.error.includes('Limit of attempts')) {
                                this.showErrorMessageText = false
                                this.$refs.loginModalError.open()
                            }
                        }
                    })
			},
            onResendCode() {
                this.form.code = null
                this.showErrorMessageText = true
                this.codeErrorMessageText = this.activeLanguage.newCodeSent
                this.onLoginSubmit()
            },
			disputeLogin() {
				let data = {
					username: this.btoken.details.username,
					email: this.btoken.details.email,
					id: this.btoken.details.id,
                    role: this.role,
					billinkToken: this.btoken.access_token
				}

				this.$store.dispatch('disputeLogIn', data)
                    .then(() => {
                        let Btoken = JSON.parse(localStorage.getItem('Btoken'))
                        if (Btoken.scope.includes('view_parent_orders')) {
                            this.$router.push('/orders')
                        } else if (Btoken.scope.includes('access_reseller_statistics')) {
                            this.$router.push('/reseller/dashboard')
                        } else {
                            this.$router.push('/')
                        }
                    })
					.catch(err => { 
                        console.error(err)
                        let { status } = err.response
                        if (status === 400) {
                            this.$refs.loginModalError.open()
                        }
                    })
			},
			onForgotPassword() {
				this.showForgotPasswordForm = !this.showForgotPasswordForm
			},
			onEmailSubmit() {
                let params = {
                	email: this.email
                }
				billinkAxios
					.get('app/password/restore', { params })
					.then(({data}) => {
						EventBus.$emit('showAlertModal', 'Please check your email')
						this.showForgotPasswordForm = false
					})
					.catch(err =>  {
                        console.error(err)
                        EventBus.$emit('showAlertModal', `Check je e-mail. Met deze link kun je je wachtwoord resetten. Let op, het kan soms een paar minuten duren. Geen mail ontvangen? Waarschijnlijk heb je een foutje gemaakt of is het emailadres niet bij ons bekend.`)
                    })
            },
            checkMaintenance() {
                store.dispatch('endLoader');

                billinkAxios
                    .get('app/maintenance/info')
                    .then(({ data }) => {
                        this.trunkDisabled = false
                        this.maintenanceLogOff = false
                        this.maintenanceMessage = data.message
                        this.maintenanceDateTo = data.date_to
                        if(data.maintenance_enabled) {
                            if (data.disable_login) {
                                this.maintenanceLogOff = true
                            } else {
                                this.maintenanceLogOn = true
                            }
                        }
                    })
                    .catch(err => {
                        store.dispatch('endLoader');
                        console.error(err)
                        
                        this.maintenanceLogOff = true
                        this.trunkDisabled = true

                        if (err.response.status === 400) {
                            this.trunkDisabled = false
                            this.maintenanceLogOff = false  
                        }
                        if ( err.response.status >= 500 ) {
                            this.maintenanceLogOff = true
                            this.trunkDisabled = true
                            store.dispatch('endLoader');
                        }
                    })

            }
        },
        components: {
            LoginModalError
        }
	}
</script>

<style lang="scss" scoped>

    .alert-dismissible .close {
        padding-top: 9px;
        i {
            font-size: 16px;
        }
    }

    .alert-danger .alert-dismissible .logOn {
        color: #544007;
        background-color: #ebd496;
        border-color: #f0cd6c;
    }

    .logOn {
        padding-right: 15px;
    }

    .logOnBody {
        margin-top: 5px;
        font-size: 12px;
    }

    .loginLinks {
        display: flex;
        justify-content: space-between;
    }

    .login .lower {
        margin-top: 10px !important;
    }
    .input-group-addon {
        i {
            line-height: 28px;
        }
    }
    .error-field {
        .input-group-addon {
            background-color: red;
            color: #ffffff;
        }
    }
    .codeMessage{
        width: 100%;
        text-align: center;
        color: #ffffff;
    }
    .errMsgText{
        color: red;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 12px;
        width: 100%;
        text-align: center;
    }
    .linkToMain {
        border-left: 4px solid #f48d21;
        padding: 30px 40px;
        color: #ffffff;
        h3 {
            color: #ffffff;
            font-size: 20px;
            text-transform: inherit;
            margin-bottom: 0;
        }
        p {
            font-size: 14px;
            padding: 20px 0;
            margin-bottom: 10px;
        }
        @media (max-width: 998px) {
            padding: 30px 0px 15px 30px;
            br {
                display: none;
            }
            a {
                min-width: 100%;
                padding: 10px;
            }
        }
    }
    @media (max-width: 998px) {
        .login .forgot-link {
            margin-left: 0 !important;
        }
        .forgot-password {
            .btn {
                width: 100%;
            }
            .form-inline .form-group {
                width: 100%;
                margin-bottom: 15px;
                padding-right: 0 !important;
                input {
                    width: 100%;
                }
            }
        }
        @media (max-width: 767px) {
            .linkToMain {
                border-left: none;
                padding: 15px;
                h3 {
                    text-align: center;
                }
            }
        }
    }
</style>
