import store from "../store/index";

export default {
	manageOrdersCheck: (to, from, next) => store.getters.userManage_orders ? next() : next(from.path),
	manageClientsCheck: (to, from, next) => store.getters.userManage_clients ? next() : next(from.path),
	manageFinanceCheck: (to, from, next) => store.getters.userManage_finance ? next() : next(from.path),
	manageCibCheck: (to, from, next) => (!!store.getters.getBillinkUserPermissions && store.getters.getBillinkUserPermissions.can_use_pending_cib) ? next() : next(from.path),
	manageCollectionCheck: (to, from, next) => (!!store.getters.getBillinkUserPermissions && store.getters.getBillinkUserPermissions.show_cib_statuses) ? next() : next(from.path),
	manageTrxCheck: (to, from, next) => (!!store.getters.getBillinkUserPermissions && store.getters.getBillinkUserPermissions.trx_enabled) ? next() : next(from.path),
	manageViewParentOrders: (to, from, next) => {
		let verifyViewParent = store.getters.userView_parent_orders
		if (verifyViewParent) {
			next('/orders')
		}
		else {
			next()
		}
	},
	manageHideInvoices: (to, from, next) => {
		let token = JSON.parse(localStorage.getItem('Btoken'))
		if (!!token.hide_invoices) {
			next('/error')
		} else {
			next()
		}
	},
	manageIdinCheck: (to, from, next) => store.getters.hasIdinScope ? next() : next(from.path)
}
