import checks from '../checks'

const Invoices = () => import('../../views/invoices/Invoices.vue'),
	InvoicesGeneration = () => import('../../views/invoices/InvoicesGeneration.vue'),
	InvoicesGenerationCib = () => import('../../views/invoices/InvoicesGenerationCib.vue')

export default [
	{
		path: '/invoices',
		name: 'invoices',
		component: Invoices,
		beforeEnter: (to, from, next) =>  {
			checks.manageViewParentOrders(to, from, next) 
			checks.manageHideInvoices(to, from, next)
		}
	},
	{
		path: '/invoices/generate',
		name: 'invoices.generate',
		component: InvoicesGeneration,
		beforeEnter: (to, from, next) =>  {
			checks.manageFinanceCheck(to, from, next) 
			checks.manageHideInvoices(to, from, next)
		}
	},
	{
		path: '/invoices/generate/cib',
		name: 'invoices.generate.cib',
		component: InvoicesGenerationCib,
		beforeEnter: (to, from, next) => { 
			checks.manageFinanceCheck(to, from, next) 
			checks.manageHideInvoices(to, from, next)
		}
	},
]