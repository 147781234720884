export default class ClientBlackListService {
    static _getAll(page = 1, params = {}) {
        return billinkAxios.get(`/app/clientblacklist/show`, { params })
    }
    static _save(data = {}) {
        return billinkAxios.post(`/app/clientblacklist/save`, data)
    }
    static _update(data = {}) {
        return billinkAxios.put(`/app/clientblacklist/save`, data)
    }
    static _remove(ids = []) {
        return billinkAxios.delete(`/app/clientblacklist/delete`, { data: { ids } })
    }
}