import * as mutations_types from '../mutations-types/blacklist/index'
import { BlackListService, ClientBlackListService } from '../../services/service-container'
import SessionStorage from '../../helpers/modules/storage/SessionStorage'

const store = {
    blacklist: [],
    activeFilters: {},
    pagData: {
        currentPage: 1,
        maxItems: 0
    }
}

const mutations = {
    [mutations_types.SET_FILTER_OPTIONS]: (state, filterOptions) => {
        state.activeFilters = filterOptions
    },

    [mutations_types.GET_FILTER_OPTIONS]: () => {
        return state.activeFilters
    }
}

const actions = {
    setFilterOptions: ({ commit }, filterOptions) => {
        commit(mutations_types.SET_FILTER_OPTIONS, filterOptions)
    }
}

const getters = {

}

export default { store, mutations, actions, getters }