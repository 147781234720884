<template>
    <div class="main">
        <div class="row">
            <div class="container">
                <p class="title">{{ $route.params.status }}</p>
                <p class="body">
                    {{ activeLanguage[$route.params.status] }}
                </p>
                <div class="row" style="display: flex;justify-content: center;">
                    <a href="/" class="link-error"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to home</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            activeLanguage: 'getErrorPage'
        })
    }
}
</script>

<style lang="scss" scoped>
    @mixin common-style {
        color: #575a5c;
        text-align: center;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    .title {
        margin-bottom: 0px !important;
        font-size: 61px;
        @include common-style;
    }
    .body {
        font-size: 24px;
        @include common-style;
    }
    .link-error {
        border: 2px solid #4db5af;
        padding: 14px 27px;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 8px;
        color: #4db5af;
        transition: .2s ease-in-out;
        &:hover {
            background: #4db5af;
            color: white;
        }
    }
</style>